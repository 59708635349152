import axios from 'axios';

const axiosApi = axios.create({
  // baseUrl: 'http://34.41.107.250/api/v2',
  baseURL: 'https://api.showfer.com/api',
  // baseURL: 'http://localhost:8000/api/v2',
  // baseURL: 'http://34.68.19.104/api/v2',
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    accept: 'application/json',
  },
});

axiosApi.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

export default axiosApi;
