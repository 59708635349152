import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API_ROUTES from '../../apiRoutes';
import API from '../../axiosApi';

export const changePageSize = createAction(
    'changePageSize',
    function prepare(pageSize) {
      return {
        payload: pageSize,
      };
    }
  );
  
  export const changePage = createAction('changePage', function prepare(page) {
    return {
      payload: page,
    };
  });

export const getBroadcasts = createAsyncThunk(
    'getBroadcasts',
    async (payload) => {
      const response = await API.get(`${API_ROUTES.broadcast}?limit=${payload.limit}&page=${payload.page}`);
      return response.data;
    }
  );

export const createNewBroadcast = createAsyncThunk(
    'createNewBroadcast',
    async (data) => {
        const response = await API.post(API_ROUTES.broadcast + '/create', data, {
          headers: {
            accept: 'application/json',
          },
        });
        return response.data;
      }
);

export const deleteBroadcast = createAsyncThunk(
    'deleteBroadcast',
    async (id) => {
        const response = await API.delete(
            API_ROUTES.broadcast + '/delete',
            {data: id},
            {
              headers: {
                accept: 'application/json',
              },
            }
          );
          return response.data;
      }
);

export const editBroadcast = createAsyncThunk(
  'editBroadcast',
  async (data) => {
    const response = await API.post(API_ROUTES.broadcast + '/edit/' + data.id, data, {
      headers: {
        accept: 'application/json',
      },
    });
    return response.data;
  }
);

