import {DEFAULT_PAGINATION, LOADING_STATUS} from "../../helper/constant";
import {createReducer} from "@reduxjs/toolkit";
import {
  addEpisodesToSeason,
  addingEpisodes,
  addingItems,
  addSeasonInSelectedSeriesItem,
  addSeriesItem,
  changeMediaChannelByField,
  changeMediaItemByField,
  changeMediaItemPositions,
  changeMrssPage,
  changeMrssPageSize,
  changeSeasonActive,
  changeSelectedSeriesItemDescription,
  changeSelectedSeriesItemOriginalName,
  deleteEpisode,
  deleteMediaChannels,
  deleteMediaItem,
  deleteSeasonInSelectedSeriesItem,
  editSelectedItemEpisodeNumber,
  getMediaChannel,
  getMediaChannels,
  previewMediaItem,
  resetCurrentMediaChannel,
  resetSelectedSeriesItem,
  resetSeriesThumbnail,
  saveMediaChannel,
  selectedSeriesItem,
  selectMediaItem,
  selectRows,
  srtMediaItem,
  updateEpisodeInList,
  updateMediaChannelItems,
  updateMediaItemInList,
  updateSeriesItem,
  uploadMediaChannelThumbnail,
  uploadMediaItemThumbnail,
  uploadSelectedSeriesItemThumbnail
} from "./mrssActions";

import {formatMediaChannelResponse} from "../../utils";
import {message} from "antd";
import ROUTE_PATH from "../../router/routePath";

const initialState = {
  list: [],
  loadingStatus: LOADING_STATUS.idle,
  currentPage: 1,
  pageSize: DEFAULT_PAGINATION.limit,
  selectedRowKeys: [],
  addingItems: [],
  selectedSeriesItem: {
    originalName: '',
    description: '',
    seasons: [],
    thumbnail: '',
  },
  addingEpisodes: [],
  currentMrss: {
    hasChanges: false,
    title: '',
    description: '',
    categories: [],
    tags: [],
    items: [],
    thumbnail: '',
    selectedMediaItem: [],
    status: LOADING_STATUS.idle,
    previewItem: undefined,
    srtItem: undefined,
  },
  filter: {
    limit: DEFAULT_PAGINATION.limit,
    page: DEFAULT_PAGINATION.page,
  },
};

const recalculatePositions = (state) => {
  state.currentMrss.items.forEach(function (mediaItem, index) {
    mediaItem.position = index;
  });
}

export const mrssReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateMediaChannelItems, (state, action) => {
      state.currentMrss = {
        ...state.currentMrss,
        items: action.payload,
        selectedMediaItem: undefined,
        hasChanges: true,
      }
    })
    .addCase(changeMediaChannelByField, (state, action) => {
      state.currentMrss = {
        ...state.currentMrss,
        hasChanges: true,
        [action.payload.field]: action.payload.value,
      }
    })
    .addCase(selectMediaItem, (state, action) => {
      state.currentMrss = {
        ...state.currentMrss,
        selectedMediaItem: action.payload,
      }
    })
    .addCase(deleteMediaItem, (state, action) => {
      state.currentMrss = {
        ...state.currentMrss,
        items: state.currentMrss.items.filter((item) => {
          return item.key !== action.payload.key;
        }),
        hasChanges: true,
        selectedMediaItem: undefined,
      };

      let ids = [];
      state.currentMrss.items.forEach(({ id }) => {
        ids.push(id);
      });

      state.selectedRowKeys = ids;

      if (! action.payload?.seasons) {
        recalculatePositions(state);
      }

      state.currentMrss.hasChanges = true;
    })
    .addCase(changeMediaItemByField, (state, action) => {
      state.currentMrss.selectedMediaItem = {
        ...state.currentMrss.selectedMediaItem,
        [action.payload.field]: action.payload.value,
      }
    })
    .addCase(resetCurrentMediaChannel, (state) => {
      state.currentMrss = initialState.currentMrss;
      state.selectedRowKeys = [];
    })
    .addCase(previewMediaItem.fulfilled, (state, action) => {
      state.currentMrss = {
        ...state.currentMrss,
        previewItem: action.payload,
      };
    })
      .addCase(srtMediaItem, (state, action) => {
        state.currentMrss.srtItem = action.payload;
      })
    .addCase(changeMrssPageSize, (state, action) => {
      state.pageSize = action.payload;
      state.currentPage = 1;
    })
    .addCase(changeMrssPage, (state, action) => {
      state.currentPage = action.payload;
    })
    .addCase(updateMediaItemInList, (state, action) => {
      state.currentMrss = {
        ...state.currentMrss,
        items: state.currentMrss.items.map((item) => {
          if (item.id === action.payload.id) {
            item = {
              ...item,
              originalName: action.payload.name,
              description: action.payload.description,
              tags: action.payload.tags,
              epnum: action.payload.epnum,
              id1: action.payload.id1,
              id2: action.payload.id2,
              thumbnail: action.payload.thumbnail,
              position: action.payload.position,
            }
          }

          return item;
        })
      }
    })
    .addCase(resetSeriesThumbnail, (state) => {
      state.selectedSeriesItem.thumbnail = initialState.selectedSeriesItem.thumbnail;
    })
    .addCase(selectedSeriesItem, (state, action) => {
      state.selectedSeriesItem = action.payload;
      if (state.selectedSeriesItem.seasons.length > 0) {
        state.selectedSeriesItem = {
          ...state.selectedSeriesItem,
          seasons: state.selectedSeriesItem.seasons.map((season, index) => ({
            ...season,
            active: (0 === index)
          }))
        }

        const seasonActive = state.selectedSeriesItem.seasons.find(season => season.active);

        if (seasonActive) {
          const seasonActiveIndex = state.selectedSeriesItem.seasons.findIndex(season => season.active);
          state.selectedRowKeys = state.selectedSeriesItem.seasons[seasonActiveIndex].episodes.map(episode => episode.id);
        }
      }
    })
    .addCase(changeSelectedSeriesItemOriginalName, (state, action) => {
      state.selectedSeriesItem.originalName = action.payload;
    })
    .addCase(changeSelectedSeriesItemDescription, (state, action) => {
      state.selectedSeriesItem.description = action.payload;
    })
    .addCase(changeSeasonActive, (state, action) => {
      state.selectedSeriesItem.seasons.forEach(season => {
          season.active = season._id === action.payload._id;
      });
    })
    .addCase(resetSelectedSeriesItem, (state) => {
      state.selectedSeriesItem = initialState.selectedSeriesItem;
      let ids = [];
      state.currentMrss.items.forEach(({ id }) => {
        ids.push(id);
      });

      state.selectedRowKeys = ids;
    })
    .addCase(addSeasonInSelectedSeriesItem, (state, action) => {
      state.selectedSeriesItem.seasons.push(action.payload);
    })
    .addCase(deleteSeasonInSelectedSeriesItem, (state, action) => {
      state.selectedSeriesItem.seasons = state.selectedSeriesItem.seasons.filter((seasons) => seasons._id !== action.payload._id);
    })
    .addCase(addSeriesItem, (state, action) => {
      state.currentMrss.items.push(action.payload);
      state.currentMrss.hasChanges = true;
    })
    .addCase(updateSeriesItem, (state, action) => {
      state.currentMrss = {
        ...state.currentMrss,
        items: state.currentMrss.items.map((item) => {
          if ((action.payload.id && item.id === action.payload.id) || item.key === action.payload.key) {
            item = {
              ...item,
              originalName: action.payload.originalName,
              description: action.payload.description,
              thumbnail: action.payload.thumbnail,
              seasons: action.payload.seasons,
            }
          }

          return item;
        })
      }
      state.currentMrss.hasChanges = true;
    })
    .addCase(addingEpisodes, (state, action) => {
      state.addingEpisodes = action.payload.filter((item) => {
        return item !== undefined;
      });
    })
    .addCase(updateEpisodeInList, (state, action) => {
      const seasonActive = state.selectedSeriesItem.seasons.find(season => season.active);

      if (seasonActive) {
        const seasonActiveIndex = state.selectedSeriesItem.seasons.findIndex(season => season.active);

        state.selectedSeriesItem.seasons[seasonActiveIndex] = {
          ...state.selectedSeriesItem.seasons[seasonActiveIndex],
          episodes: state.selectedSeriesItem.seasons[seasonActiveIndex].episodes.map((episode) => {
            if (episode.id === action.payload.id) {
              episode = {
                ...episode,
                originalName: action.payload.name,
                description: action.payload.description,
                tags: action.payload.tags,
                epnum: action.payload.epnum,
                id1: action.payload.id1,
                id2: action.payload.id2,
                thumbnail: action.payload.thumbnail,
                position: action.payload.position,
              }
            }

            return episode;
          })
        }
      }
    })
    .addCase(deleteEpisode, (state, action) => {
      const seasonActive = state.selectedSeriesItem.seasons.find(season => season.active);

      if (seasonActive) {
        const seasonActiveIndex = state.selectedSeriesItem.seasons.findIndex(season => season.active);

        state.selectedSeriesItem.seasons[seasonActiveIndex] = {
          ...state.selectedSeriesItem.seasons[seasonActiveIndex],
          episodes: state.selectedSeriesItem.seasons[seasonActiveIndex].episodes.filter( (episode) => episode.id !== action.payload.id )
        }

        state.selectedRowKeys = state.selectedSeriesItem.seasons[seasonActiveIndex].episodes.map(episode => episode.id);
      }
    })
    .addCase(addEpisodesToSeason, (state, action) => {
      const seasonActive = state.selectedSeriesItem.seasons.find(season => season.active);

      if (seasonActive) {
        const seasonActiveIndex = state.selectedSeriesItem.seasons.findIndex(season => season.active);

        state.selectedSeriesItem.seasons[seasonActiveIndex].episodes = action.payload;
      }

      state.addingEpisodes = [];
    })
    .addCase(editSelectedItemEpisodeNumber, (state, action) => {
      state.selectedSeriesItem = {
        ...state.selectedSeriesItem,
        seasons: state.selectedSeriesItem.seasons.map((item) => {
          if (item._id === action.payload.season._id) {
            item = {
              ...item,
              num: action.payload.num,
            }
          }

          return item;
        })
      }
    })
    .addCase(getMediaChannels.pending, (state) => {
      state.loadingStatus = LOADING_STATUS.loading;
    })
    .addCase(getMediaChannels.fulfilled, (state, action) => {
      state.loadingStatus = LOADING_STATUS.success;
      const { rows, totalItems } = action.payload.data || {};
      state.list = rows;
      state.totalItems = totalItems;
    })
    .addCase(changeMediaItemPositions, (state, action) => {
      const { newId, oldId } = action.payload;

      const itemIds = state.currentMrss.items.map(item => item.key);
      const oldIndex = itemIds.indexOf(oldId);
      const newIndex = itemIds.indexOf(newId);

      const element = state.currentMrss.items[oldIndex];
      state.currentMrss.items.splice(oldIndex, 1);
      state.currentMrss.items.splice(newIndex, 0, element);
      state.currentMrss.hasChanges = true;

      recalculatePositions(state);
    })
    .addCase(getMediaChannel.pending, (state) => {
      state.currentMrss.status = LOADING_STATUS.loading;
    })
    .addCase(getMediaChannel.fulfilled, (state, action) => {
      state.currentMrss.status = LOADING_STATUS.success;
      state.currentMrss = formatMediaChannelResponse(action.payload.data);
      state.currentMrss.hasChanges = false;

      let ids = [];
      state.currentMrss.items.forEach(({ id }) => {
        ids.push(id);
      });

      state.selectedRowKeys = ids;
      recalculatePositions(state);
    })
    .addCase(saveMediaChannel.fulfilled, (state, action) => {
      const id = action.payload?.data?._id;
      const currentLink = window.location.href;
      if (currentLink.includes('create')) {
        message.success('MRSS created successfully');
        window.location.href = ROUTE_PATH.mrssEditor.replace(':id', id);
      } else {
        state.currentMrss.hasChanges = false;
        message.success('MRSS updated successfully');
      }
    })
    .addCase(selectRows, (state, action) => {
      state.selectedRowKeys = action.payload;
    })
    .addCase(addingItems, (state, action) => {
      state.addingItems = action.payload.filter((item) => {
        return item !== undefined;
      });
    })
    .addCase(deleteMediaChannels.fulfilled, (state, action) => {
      let newList = [];
      action.payload.data.ids.forEach((id) => {
        newList = state.list.filter((s) => s._id !== id);
      });
      state.list = newList;
    })
    .addCase(uploadMediaChannelThumbnail.fulfilled, (state, action) => {
      state.currentMrss.thumbnail = action.payload.data;
      state.currentMrss.hasChanges = true;

      message.success('Thumbnail successfully uploaded');
    })
    .addCase(uploadMediaItemThumbnail.fulfilled, (state, action) => {
      state.currentMrss.selectedMediaItem.thumbnail = action.payload.data;

      message.success('Thumbnail successfully uploaded');
    })
    .addCase(uploadSelectedSeriesItemThumbnail.fulfilled, (state, action) => {
      state.selectedSeriesItem.thumbnail = action.payload.data;

      message.success('Thumbnail successfully uploaded');
    })
    .addCase(uploadMediaChannelThumbnail.rejected, () => {
      message.error('Thumbnail uploading failed');
    })
    .addCase(uploadMediaItemThumbnail.rejected, () => {
      message.error('Thumbnail uploading failed');
    })
    .addCase(uploadSelectedSeriesItemThumbnail.rejected, () => {
      message.error('Thumbnail uploading failed');
    });
});