import React from "react";
import 'antd/dist/antd.css';

import {AppRoutes} from './router/routeConfig';
import ROUTES from './router/appRoutes';

function App() {
  return (
    <div className="App">
      <AppRoutes routesConfig={ROUTES}/>
    </div>
  );
}

export default App;
