import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API_ROUTES from '../../apiRoutes';
import API from '../../axiosApi';

export const getAuthor = createAction('getAuthor', function prepare() {
  const author = localStorage.getItem('author');

  if (author !== 'undefined' && author !== null && author !== '') {
    const payload = JSON.parse(localStorage.getItem('author'));
    return { payload };
  } else {
    return {};
  }
});

export const getUserList = createAsyncThunk('getUserList', async (payload) => {
  const response = await API.get(
    `${API_ROUTES.users}?limit=${payload.limit}&page=${payload.page}`
  );
  return response.data;
});

export const getUserById = createAsyncThunk('getUserById', async (id) => {
  const response = await API.get(`${API_ROUTES.users}/${id}`);
  return response.data;
});

export const createUserByAdmin = createAsyncThunk(
  'createByAdmin',
  async (payload) => {
    const response = await API.post(
      `${API_ROUTES.users}/createByAdmin`,
      payload
    );
    return response.data;
  }
);

export const updateUser = createAsyncThunk('updateUser', async (payload) => {
  const response = await API.put(
    `${API_ROUTES.users}/${payload._id}`,
    payload,
    {
      headers: {
        accept: 'application/json',
      },
    }
  );
  return response.data;
});

export const deleteUser = createAsyncThunk('deleteUser', async (id) => {
  const response = await API.delete(`${API_ROUTES.users}/${id}`);
  return response.data;
});

export const changeUserPageSize = createAction(
  'changeUserPageSize',
  function prepare(pageSize) {
    return {
      payload: pageSize,
    };
  }
);

export const changeUserPage = createAction('changeUserPage', function prepare(page) {
  return {
    payload: page,
  };
});

export const setSelectedUser = createAction(
  'setSelectedUser',
  function prepare(user) {
    return {
      payload: user,
    };
  }
);

export const resetSelectedUser = createAction('resetSelectedUser');

export const updatePageNumber = createAction('updatePageNumber');
