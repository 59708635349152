import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API_ROUTES from '../../apiRoutes';
import API from '../../axiosApi';
import axiosApi from "../../axiosApi";

export const getBucketList = createAsyncThunk(
  'getBucketList',
  async (payload) => {
    const response = await API.get(
      `${ API_ROUTES.bucket }?limit=${ payload.limit }&page=${ payload.page }`
    );
    return response.data;
  }
);

export const getAllBucket = createAsyncThunk(
  'getAllBucket',
  async () => {
    const response = await API.get(
      `${API_ROUTES.bucket}?limit=-1&page=-1`
    );
    return response.data;
  }
);

export const getBucketByUser = createAsyncThunk(
  'getBucketByUser',
  async () => {
    const response = await API.get(
      `${API_ROUTES.bucket}/by_user`
    );
    return response.data;
  }
)

export const getBucketDetail = createAsyncThunk(
  'getBucketDetail',
  async (id) => {
    const response = await API.get(`${API_ROUTES.bucket}/${id}`);
    return response.data;
  }
);

export const deleteBuckets = createAsyncThunk(
  'deleteBuckets',
  async (ids) => {
    const response = await API.delete(
      API_ROUTES.bucket,
      {
        data: { ids },
      },
      {
        headers: {
          accept: 'application/json',
        },
      }
    );
    return response.data;
  }
);


export const checkEmptyFolder = createAsyncThunk('checkEmptyFolder', async (data) => {
    if(data?.selectedBucket && data?.item) {
        let item = data.item;
        let result =  await axiosApi.post(`${API_ROUTES.bucket}/${data.selectedBucket}/empty_folder`, {
            item
        });
        return result.data;

    }
    return false;
});

export const deleteFileOrFolder = createAsyncThunk('deleteFileOrFolder', async (data) => {
    if(data?.selectedBucket && data?.item) {
        let item = data.item;
        let result =  await axiosApi.post(`${API_ROUTES.bucket}/${data.selectedBucket}/delete`, {
            item
        });
        return result.data;

    }
    return false;
});

export const deleteManyFiles = createAsyncThunk('deleteManyFiles', async (data) => {

    if(data?.selectedBucket && data?.items) {
        let items = data.items;
        let result =  await axiosApi.post(`${API_ROUTES.bucket}/${data.selectedBucket}/delete_many`, {
            items
        });
        return result.data;

    }
    return false;
});

export const changeBucketPageSize = createAction(
  'changeBucketPageSize',
  function prepare(pageSize) {
    return {
      payload: pageSize,
    };
  }
);

export const changeBucketPage = createAction(
  'changeBucketPage',
  function prepare(page) {
    return {
      payload: page,
    };
  }
);

export const changeCurrentBucket = createAction(
  'changeCurrentBucket',
  function prepare(bucket) {
    return {
      payload: bucket,
    };
  }
);

export const updateBucket = createAsyncThunk(
  'updateBucket',
  async (bucket) => {
    const id = bucket._id;
    const response = await API.put(`${ API_ROUTES.bucket }/${ id }`, bucket, {
      headers: {
        accept: 'application/json',
      },
    });
    return response.data;
  }
);

export const createBucket = createAsyncThunk(
  'createBucket',
  async (bucket) => {
    const response = await API.post(API_ROUTES.bucket, bucket, {
      headers: {
        accept: 'application/json',
      },
    });
    return response.data;
  }
);

export const resetCurrentBucket = createAction('resetCurrentBucket');
