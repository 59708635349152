import React from 'react';
import {Spin} from "antd";

const Spinner = () => {
  return (
    <div className="spinnerContainer">
      <Spin size="large" style={{marginLeft: '50%', marginTop: "40vh"}}/>
    </div>
  );
};

export default Spinner;
