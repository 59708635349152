import axiosApi from "../axiosApi";

export default class AsyncQueue {
    constructor() {
        this.countInWork = 0;
        this.maxCount = 5;
    }

    add(promise) {
        // this.queue.push(promise);

        return new Promise((resolve) => {
            let that = this;
            let id = setInterval(() => {
                if (this.countInWork < this.maxCount) {
                    let result = this.request(promise);
                    clearInterval(id)
                    resolve(result);
                }
            }, 100, that);

        })
    }

    async request(query) {
        // let query = this.queue.shift();
        this.countInWork++;
        return await axiosApi.post(query.url, query.data, query.config)
            .catch(function (error) {
                    query.errorFunc(error);
            })
            .finally(() => {
                this.countInWork--;
            })
    }

}