import { createReducer } from '@reduxjs/toolkit';
import { DEFAULT_PAGINATION, LOADING_STATUS } from '../../helper/constant';

import {
    getBroadcasts,
    createNewBroadcast,
    deleteBroadcast,
    editBroadcast, changePageSize, changePage,
} from './broadcastActions';

const initialState = {
    list: [],
    loadingStatus: LOADING_STATUS.idle,
    currentPage: 1,
    pageSize: DEFAULT_PAGINATION.limit,
    currentBroadcast: {},
    defaultValues: {
        inType: 'srt',
        outType: 'srt',
    },
    types: [
        { value: "srt", label: "SRT" },
        // { value: "m3u8", label: "m3u8" },
    ]
};

export const broadcastReducer = createReducer(
    initialState,
    (builder) => {
        builder
            .addCase(getBroadcasts.pending, (state) => {
                state.status = LOADING_STATUS.loading;
            })
            .addCase(getBroadcasts.fulfilled, (state, action) => {
                state.status = LOADING_STATUS.success;
                const { rows, totalItems } = action.payload.data || {};
                state.list = rows;
                state.totalItems = totalItems;
            })
            .addCase(createNewBroadcast.fulfilled, (_state, action) => {
                window.location.reload();
              })
            .addCase(deleteBroadcast.fulfilled, (_state, action) => {
                window.location.reload();
              })
            .addCase(changePageSize, (state, action) => {
                state.pageSize = action.payload;
                state.currentPage = 1;
              })
            .addCase(changePage, (state, action) => {
                state.currentPage = action.payload;
            })
            .addCase(editBroadcast.fulfilled, (state, action) => {
                window.location.reload();
              })

    }
);