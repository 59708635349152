import { createReducer } from "@reduxjs/toolkit";
import {
  changePeriod,
  resetFilters,
  resetSearchData,
  applyFilters,
  updateViewersChart,
  updateWatchTimeChart,
} from "./channelAmsAction";

const initialState = {
  data: [],
  period: 'day',
  filters: {
    channel_name: null,
    content_name: null,
    country: null,
    id1: null,
    id2: null,
  }
};

export const channelAmsReducer = createReducer(initialState, (builder ) => {
  builder
    .addCase(changePeriod, (state, action) => {
      state.period = action.payload;
    })
    .addCase(resetFilters, (state) => {
      state.filters = initialState.filters;
    })
    .addCase(resetSearchData, (state) => {
      state.filters = initialState.filters;
      state.period = initialState.period;
    })
    .addCase(applyFilters, (state, action) => {
      state.filters = action.payload;
    })
    .addCase(updateViewersChart.fulfilled, (state, action) => {
      state.data = action.payload.rows;
    })
    .addCase(updateWatchTimeChart.fulfilled, (state, action) => {
      state.data = action.payload.rows;
    });
});