import { createReducer } from '@reduxjs/toolkit';
import { message } from 'antd';
import { DEFAULT_PAGINATION, LOADING_STATUS } from '../../helper/constant';
import ROUTE_PATH from '../../router/routePath';
import {
  changeCurrentStorage,
  changePage,
  changePageSize,
  createStorage,
  deleteStorages,
  getStorageDetail,
  getStorageList,
  getAllStorageList,
  resetCurrentStorage,
  updateStorage,
} from './storageActions';

const initialState = {
  list: [],
  loadingStatus: LOADING_STATUS.idle,
  currentPage: 1,
  pageSize: DEFAULT_PAGINATION.limit,
  currentStorage: {},
};

export const storageReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getStorageList.pending, (state) => {
      state.loadingStatus = LOADING_STATUS.loading;
    })
    .addCase(getStorageList.fulfilled, (state, action) => {
      state.loadingStatus = LOADING_STATUS.success;
      const { rows, totalItems } = action.payload.data || {};
      state.list = rows;
      state.totalItems = totalItems;
    })
    .addCase(getAllStorageList.pending, (state) => {
      state.loadingStatus = LOADING_STATUS.loading;
    })
    .addCase(getAllStorageList.fulfilled, (state, action) => {
      state.loadingStatus = LOADING_STATUS.success;
      state.list = action.payload.data;
    })
    .addCase(deleteStorages.fulfilled, (state, action) => {
      let newList = [];
      action.payload.data.ids.forEach((id) => {
        newList = state.list.filter((s) => s._id !== id);
      });
      state.list = newList;
    })
    .addCase(changePageSize, (state, action) => {
      state.pageSize = action.payload;
      state.currentPage = 1;
    })
    .addCase(changePage, (state, action) => {
      state.currentPage = action.payload;
    })
    .addCase(getStorageDetail.fulfilled, (state, action) => {
      state.currentStorage = action.payload.data;
    })
    .addCase(changeCurrentStorage, (state, action) => {
      const { name, value } = action.payload;
      state.currentStorage = {
        ...state.currentStorage,
        [name]: value,
      };
    })
    .addCase(updateStorage.fulfilled, () => {
      message.success('Updated successfully!', 5);
    })
    .addCase(updateStorage.rejected, () => {
      message.error('Something went wrong!', 5);
    })
    .addCase(createStorage.fulfilled, (_state, action) => {
      const id = action.payload?.data?._id;
      window.location.href = ROUTE_PATH.storageEditor.replace(':id', id);
    })
    .addCase(createStorage.rejected, () => {
      message.error('Something went wrong!', 5);
    })
    .addCase(resetCurrentStorage, (state) => {
      state.currentStorage = {};
    });
});
