import { createReducer } from '@reduxjs/toolkit';
import { message } from 'antd';
import { DEFAULT_PAGINATION, LOADING_STATUS } from '../../helper/constant';
import {
  changeUserPage,
  changeUserPageSize,
  createUserByAdmin,
  deleteUser,
  getAuthor,
  getUserById,
  getUserList,
  resetSelectedUser,
  setSelectedUser,
  updateUser,
  updatePageNumber
} from './userActions';
import crypto from "crypto-browserify";


const initialState = {
  list: [],
  author: {
    role: null,
  },
  selectedUser: {},
  currentPage: 1,
  pageSize: DEFAULT_PAGINATION.limit,
  loadingStatus: LOADING_STATUS.idle,
  message: '',
};

export const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getUserList.pending, (state) => {
      state.loadingStatus = LOADING_STATUS.loading;
    })
    .addCase(getUserList.fulfilled, (state, action) => {
      state.loadingStatus = LOADING_STATUS.success;
      const { rows, totalItems } = action.payload.data || {};
      state.list = rows;
      state.totalItems = totalItems;
    })
    .addCase(getUserById.fulfilled, (state, action) => {
      state.loadingStatus = LOADING_STATUS.success;
      state.selectedUser = action.payload.data;
      if(!state.selectedUser.broadcastKey) {
        state.selectedUser.broadcastKey = crypto.randomBytes(16).toString("hex");
      }
      state.message = action.payload.message;
    })
    .addCase(createUserByAdmin.fulfilled, (state, action) => {
      state.loadingStatus = LOADING_STATUS.success;
      message.success('Updated successfully!', 5);
    })
    .addCase(createUserByAdmin.rejected, (state) => {
      state.loadingStatus = LOADING_STATUS.error;
      message.error('Something went wrong!', 5);
    })
    .addCase(updateUser.fulfilled, (state, action) => {
      state.loadingStatus = LOADING_STATUS.success;
      state.selectedUser = action.payload.data;
      const updatedUserIndex = state.list.findIndex(
        (user) => user._id === state.selectedUser._id
      );
      if (updatedUserIndex !== -1) {
        state.list[updatedUserIndex].isDisabled =
          action.payload.data.isDisabled;
      }
      message.success('Updated successfully!', 5);
    })
    .addCase(updateUser.rejected, (state) => {
      state.loadingStatus = LOADING_STATUS.error;
      message.error('Something went wrong!', 5);
    })
    .addCase(deleteUser.fulfilled, (state, action) => {
      state.list = state.list.filter((storage) => {
        return action.payload?.data?._id !== storage._id;
      });
    })
    .addCase(changeUserPageSize, (state, action) => {
      state.pageSize = action.payload;
      state.currentPage = 1;
    })
    .addCase(updatePageNumber, (state, action) => {
      state.currentPage = action.payload;
    })
    .addCase(changeUserPage, (state, action) => {
      state.currentPage = action.payload;
    })
    .addCase(getAuthor, (state, action) => {
      state.author = action.payload;
    })
    .addCase(setSelectedUser, (state, action) => {
      const { name, value } = action.payload;
      state.selectedUser = {
        ...state.selectedUser,
        [name]: value,
      };
    })
    .addCase(resetSelectedUser, (state) => {
      state.selectedUser = {};
    });
});
