import { createReducer } from '@reduxjs/toolkit';
import { message } from 'antd';
import { DEFAULT_PAGINATION, LOADING_STATUS } from '../../helper/constant';
import ROUTE_PATH from '../../router/routePath';
import {
  changeCurrentBucket,
  changeBucketPage,
  changeBucketPageSize,
  createBucket,
  deleteBuckets,
  getBucketDetail,
  getBucketList,
  getAllBucket,
  resetCurrentBucket,
  updateBucket,
  getBucketByUser,
} from './bucketActions';
import {createCategory} from "../category/categoryActions";

const initialState = {
  list: [],
  loadingStatus: LOADING_STATUS.loading,
  currentPage: 1,
  pageSize: DEFAULT_PAGINATION.limit,
  currentBucket: {},
};

export const bucketReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getBucketList.pending, (state) => {
      state.loadingStatus = LOADING_STATUS.loading;
    })
    .addCase(getBucketList.fulfilled, (state, action) => {
      state.loadingStatus = LOADING_STATUS.success;
      const { rows, totalItems } = action.payload.data || {};
      state.list = rows;
      state.totalItems = totalItems;
    })
    .addCase(getAllBucket.pending, (state) => {
      state.loadingStatus = LOADING_STATUS.loading;
    })
    .addCase(getAllBucket.fulfilled, (state, action) => {
      state.loadingStatus = LOADING_STATUS.success;
      state.list = action.payload.data;
    })
    .addCase(getBucketByUser.pending, (state) => {
      state.loadingStatus = LOADING_STATUS.loading;
    })
    .addCase(getBucketByUser.fulfilled, (state, action) => {
      state.loadingStatus = LOADING_STATUS.success;
      state.currentBucket = action.payload.data;
    })
    .addCase(deleteBuckets.fulfilled, (state, action) => {
      let newList = [];
      action.payload.data.ids.forEach((id) => {
        newList = state.list.filter((s) => s._id !== id);
      });
      state.list = newList;
    })

    .addCase(changeBucketPageSize, (state, action) => {
      state.pageSize = action.payload;
      state.currentPage = 1;
    })
    .addCase(changeBucketPage, (state, action) => {
      state.currentPage = action.payload;
    })
    .addCase(getBucketDetail.fulfilled, (state, action) => {
      state.currentBucket = action.payload.data;
      if(!action.payload.data)  {
        window.location.href = ROUTE_PATH.bucket

      }
    })
    .addCase(getBucketDetail.rejected, () => {
      window.location.href = ROUTE_PATH.bucket
    })
    .addCase(changeCurrentBucket, (state, action) => {
      const { name, value } = action.payload;
      state.currentBucket = {
        ...state.currentBucket,
        [name]: value,
      };
    })
    .addCase(updateBucket.fulfilled, () => {
      message.success('Updated successfully!', 5);
    })
    .addCase(updateBucket.rejected, () => {
      message.error('Something went wrong!', 5);
    })
    .addCase(createBucket.fulfilled, (_state, action) => {
      const id = action.payload?.data?._id;
      if(id) {
        window.location.href = ROUTE_PATH.bucketEdit.replace(':id', id);
      } else {
        let error = 'Something went wrong!';
        if(action.payload?.message) {
          error = action.payload?.message;
        }
        message.error(error, 5);
      }
    })
    .addCase(createBucket.rejected, () => {
      message.error('Something went wrong!', 5);
    })
    .addCase(resetCurrentBucket, (state) => {
      state.currentBucket = {};
    });
});
