import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API_ROUTES from '../../apiRoutes';
import API from '../../axiosApi';

export const changeRTMPPageSize = createAction(
    'changeRTMPPageSize',
    function prepare(pageSize) {
      return {
        payload: pageSize,
      };
    }
  );
  
  export const changeRTMPPage = createAction('changeRTMPPage', function prepare(page) {
    return {
      payload: page,
    };
  });

export const getRTMPs = createAsyncThunk(
    'getRTMPs',
    async (payload) => {
      const response = await API.get(`${API_ROUTES.rtmp}?limit=${payload.limit}&page=${payload.page}`);
      return response.data;
    }
  );

export const createNewRTMP = createAsyncThunk(
    'createNewRTMP',
    async (data) => {
        const response = await API.post(API_ROUTES.rtmp + '/create', data, {
          headers: {
            accept: 'application/json',
          },
        });
        return response.data;
      }
);

export const deleteRTMP = createAsyncThunk(
    'deleteRTMP',
    async (id) => {
        const response = await API.delete(
            API_ROUTES.rtmp + '/delete',
            {data: id},
            {
              headers: {
                accept: 'application/json',
              },
            }
          );
          return response.data;
      }
);

export const editRTMP = createAsyncThunk(
  'editRTMP',
  async (data) => {
    const response = await API.post(API_ROUTES.rtmp + '/edit/' + data.id, data, {
      headers: {
        accept: 'application/json',
      },
    });
    return response.data;
  }
);

export const getAllRTMP = createAsyncThunk(
  'getAllRTMP',
  async () => {
    const response = await API.get(API_ROUTES.rtmp + '/all', {
      headers: {
        accept: 'application/json',
      },
    });
    return response.data;
  }
)
