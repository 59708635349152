import { createReducer } from '@reduxjs/toolkit';
import { message } from 'antd';
import { DEFAULT_PAGINATION, LOADING_STATUS } from '../../helper/constant';
import ROUTE_PATH from '../../router/routePath';
import {
  changeCurrentCategory,
  changeCategoryPage,
  changeCategoryPageSize,
  createCategory,
  deleteCategories,
  getCategoryDetail,
  getCategoryList,
  resetCurrentCategory,
  updateCategory,
} from './categoryActions';

const initialState = {
  list: [],
  loadingStatus: LOADING_STATUS.idle,
  currentPage: 1,
  pageSize: DEFAULT_PAGINATION.limit,
  currentCategory: {},
};

export const categoryReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getCategoryList.pending, (state) => {
      state.status = LOADING_STATUS.loading;
    })
    .addCase(getCategoryList.fulfilled, (state, action) => {
      state.status = LOADING_STATUS.success;
      const { rows, totalItems } = action.payload.data || {};
      state.list = rows;
      state.totalItems = totalItems;
    })
    .addCase(deleteCategories.fulfilled, (state, action) => {
      let newList = [];
      action.payload.data.ids.forEach((id) => {
        newList = state.list.filter((s) => s._id !== id);
      });
      state.list = newList;
    })
    .addCase(changeCategoryPageSize, (state, action) => {
      state.pageSize = action.payload;
      state.currentPage = 1;
    })
    .addCase(changeCategoryPage, (state, action) => {
      state.currentPage = action.payload;
    })
    .addCase(getCategoryDetail.fulfilled, (state, action) => {
      state.currentCategory = action.payload.data;
    })
    .addCase(changeCurrentCategory, (state, action) => {
      const { name, value } = action.payload;
      state.currentCategory = {
        ...state.currentCategory,
        [name]: value,
      };
    })
    .addCase(updateCategory.fulfilled, () => {
      message.success('Updated successfully!', 5);
    })
    .addCase(updateCategory.rejected, () => {
      message.error('Something went wrong!', 5);
    })
    .addCase(createCategory.fulfilled, (_state, action) => {
      const id = action.payload?.data?._id;
      window.location.href = ROUTE_PATH.categoryEditor.replace(':id', id);
    })
    .addCase(createCategory.rejected, () => {
      message.error('Something went wrong!', 5);
    })
    .addCase(resetCurrentCategory, (state) => {
      state.currentCategory = {};
    });
});
