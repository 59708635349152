import React, { Fragment, Suspense } from "react";
import { Route, Routes } from 'react-router-dom';

import Spinner from "../components/Spinner/Spinner";

/**
 * routes: {
    path: string;
    component: React.LazyExoticComponent<ComponentType<unknown>> | ComponentType<unknown>;
    exact?: boolean;
    guard?: React.LazyExoticComponent<ComponentType<unknown>> | ComponentType<unknown>;
    layout?: React.LazyExoticComponent<ComponentType<unknown>> | ComponentType<unknown>;
    routes?: IRoute[];
  }[]
 */
function renderRoutes(routes, fallback = <Spinner/>, prefix = '') {
  return routes && Array.isArray(routes) ? (
    <Suspense fallback={fallback}>
      <Routes>
        {routes.map((route) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          const path = prefix + route.path;

          return (
            <Route
              key={path}
              path={path}
              exact={route.exact}
              element={
                <Guard>
                  <Layout>
                    <Component />
                  </Layout>
                </Guard>
              }
            />
          );
        })}
      </Routes>
    </Suspense>
  ) : null;
}

export function AppRoutes({ routesConfig, fallback }) {
  return renderRoutes(routesConfig, fallback);
}

