import { createReducer } from '@reduxjs/toolkit';
import { LOADING_STATUS } from '../../helper/constant';
import {
  getRoles,
  getRoleById,
} from './actions';

const initialState = {
  list: [],
  selectedRole: {},
  loadingStatus: LOADING_STATUS.idle,
};

export const roleReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getRoles.pending, (state) => {
      state.loadingStatus = LOADING_STATUS.loading;
    })
    .addCase(getRoles.fulfilled, (state, action) => {
      state.loadingStatus = LOADING_STATUS.success;
      state.list = action.payload.data;
    })
    .addCase(getRoleById.fulfilled, (state, action) => {
      state.selectedRole = action.payload.data;
    });
});
