import { createReducer } from '@reduxjs/toolkit';
import { DEFAULT_PAGINATION, LOADING_STATUS } from '../../helper/constant';
import { changeFileFilter, getFiles, selectFiles } from './fileActions';

const initialState = {
  list: [],
  loadingStatus: LOADING_STATUS.idle,
  filter: {
    limit: DEFAULT_PAGINATION.limit,
    page: DEFAULT_PAGINATION.page,
    name: '',
    sortField: 'fileName',
    sortDirection: 'desc',
    location: ''
  },
  selectedFiles: [],
  totalDuration: 0,
  totalItems: 0,
};

export const fileReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getFiles.pending, (state) => {
      state.loadingStatus = LOADING_STATUS.loading;
    })
    .addCase(getFiles.fulfilled, (state, action) => {
      const { rows, totalItems } = action.payload.data;
      state.loadingStatus = LOADING_STATUS.success;
      state.list = rows;
      state.totalItems = totalItems;
    })
    .addCase(selectFiles, (state, action) => {
      state.selectedFiles = action.payload;
      let totalDuration = 0;
      (state.selectedFiles || []).forEach((file) => {
        totalDuration += file?.duration || 0;
      });
      state.totalDuration = totalDuration;
    })
    .addCase(changeFileFilter, (state, action) => {
      state.filter = action.payload;
    });
});
