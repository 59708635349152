import momentTimezone from "moment-timezone";

export const buildTimeString = (displayTime, showHour) => {
  const h = Math.floor(displayTime / 3600);
  const m = Math.floor((displayTime / 60) % 60);
  let s = Math.floor(displayTime % 60);
  if (s < 10) s = `0${s}`;
  let text = `${m}:${s}`;

  if (m < 10) text = `0${text}`;
  if (showHour) {
    text = `${h}:${text}`;
  }
  return text;
};

export const formatHlsPlayload = (hls) => {
  const days = hls.streamList.map((stream, index) => {
    return {
      title: stream.title ?? '',
      order: index,
      items: [
        ...stream.items.map((item, index) => {
          return {
            ...item,
            file: item.id,
            order: index,
            key: item.key,
            ads: item.ads,
          };
        }),
      ],
      showDates: [...stream.multiDates],
    };
  });

  return {
    startAt: new Date(hls.selectedDate || new Date()).toISOString(),
    days,
    repeat: hls.repeatSchedule,
    enableEmbeddedAds: hls.monetize,
    enableSrt: hls.enableSrt,
    payPerView: hls.payPerView,
    price: hls.price,
    timezone: hls.timezone,
    adsTimer: {
      advertisementBlock: hls?.adsTimer?.timer?.advertisementBlock?._id || undefined,
      timer: hls?.adsTimer?.timer?.timer || 0,
      adsByPlatform: hls?.adsTimer?.timer?.adsByPlatform ?? [],
    },
    title: hls.title,
    fillers: [
      ...hls.fillers.map((item) => {
        return {
          ...item,
          file: item.id,
          key: item.key,
        }
      })
    ],
  };
};

export const formatMediaChannelResponse = (mediaChannel) => {
  const items = mediaChannel.items.map((item) => {
    return formatMediaItem(item);
  });

  const series = mediaChannel.series.map((item) => {
    return formatSeriesItem(item);
  });

  return {
    title: mediaChannel.title,
    description: mediaChannel.description,
    thumbnail: mediaChannel.thumbnail,
    categories: mediaChannel.categories,
    tags: mediaChannel.tags,
    items: items
      .concat(series)
      .sort( (itemFirst, itemSecond) => (itemFirst.position > itemSecond.position) ? 1 : ((itemSecond.position > itemFirst.position) ? -1 : 0) )
  };
};

export const formatMediaChannelRequest = (mediaChannel) => {
  const items = mediaChannel.items.filter((item) => ! item.hasOwnProperty('seasons')).map((item) => {
    return formatMediaItem(item)
  });

  const series = mediaChannel.items.filter((item) => item.hasOwnProperty('seasons')).map((item) => {
    return formatSeriesItem(item)
  });

  return {
    title: mediaChannel.title,
    description: mediaChannel.description,
    thumbnail: mediaChannel.thumbnail,
    categories: mediaChannel.categories,
    tags: mediaChannel.tags,
    series: series,
    items: items
  };
};

export const formatMediaItem = (item) => {
  const { _id, fileName, description, duration, name, tags, epnum, storage, bucket, id1, id2, location, thumbnail, isSRTExist } = item.file?.[0] || {};

  return {
    id: _id,
    file: [ item.file?.[0] ?? item.file ],
    fileName,
    duration,
    storage: {
      cdnToken: storage?.cdnToken ?? storage?.[0]?.cdnToken,
      cdnUrl: storage?.cdnUrl ?? storage?.[0]?.cdnUrl,
    },
    bucket: {
      bucketName: bucket?.bucketName ?? bucket?.[0]?.bucketName,
    },
    key: item.key,
    originalName: name,
    description: description || '',
    tags: tags,
    epnum: epnum || '',
    id1,
    id2,
    location,
    thumbnail,
    position: item?.position ?? 0,
    isSRTExist: isSRTExist === undefined ? false : isSRTExist,
  };
}
export const formatSeriesItem = (item) => {
  const { id, key, originalName, description, position, thumbnail } = item;

  const seasons = item.seasons.map((season) => {
    const { _id, num } = season;

    const episodes = season.episodes.map((episode) => {
      const { _id, fileName, description, duration, name, tags, epnum, storage, bucket, id1, id2, location, thumbnail } = episode.file?.[0] || {};

      return {
        id: _id,
        file: [ episode.file?.[0] ?? episode.file ],
        key: episode.key,
        fileName,
        duration,
        storage: {
          cdnToken: storage?.cdnToken ?? storage?.[0]?.cdnToken,
          cdnUrl: storage?.cdnUrl ?? storage?.[0]?.cdnUrl,
        },
        bucket: {
          bucketName: bucket?.bucketName ?? bucket?.[0]?.bucketName,
        },
        originalName: name,
        description: description || '',
        tags,
        epnum: epnum || '',
        id1,
        id2,
        location,
        thumbnail,
      }
    });

    return {
      _id, num, episodes
    }
  });

  return {
    id, key, originalName, description, position, thumbnail, seasons, isSRTExist: false
  };
}

export const formatHlsResponse = (hls) => {
  const streamList = hls?.days.map((stream) => {
    return {
      ...stream,
      items: [
        ...stream.items
          ?.sort((a, b) => ((a.order || 0) > (b.order || 0) ? 1 : -1))
          .map((item, index) => {
            const { _id, rtmpStream, fileName, description, duration, name, epnum, status, isLiveStream, c_k, isSRTExist, c_location, location, c_filename, storage, bucket, id1, id2 } =
              item.file?.[0] || {};
            return {
              id: _id,
              fileName,
              duration,
              c_k,
              c_location,
              isSRTExist,
              location,
              c_filename,
              start: item.start,
              customStart: item.customStart,
              storage: {
                cdnToken: storage?.[0]?.cdnToken,
                cdnUrl: storage?.[0]?.cdnUrl,
              },
              bucket: {
                bucketName: bucket?.bucketName ?? bucket?.[0]?.bucketName,
              },
              key: item.key,
              payPerView: item.payPerView,
              price: item.price,
              ads: item.advertisementBlockStreamItems.map((ad) => {
                const { key, startTime, advertisementBlock, adsByPlatform } = ad || {};
                const { duration, id, title } = advertisementBlock || {};
                return {
                  key,
                  startTime,
                  duration,
                  id,
                  title,
                  adsByPlatform,
                };
              }),
              originalName: name,
              order: index,
              description: description || '',
              epnum: epnum || '',
              status,
              isLiveStream,
              id1,
              id2,
              rtmpStream: rtmpStream?.[0],
            };
          }),
      ],
      id: stream._id,
      multiDates: [...(stream?.showDates || [])],
    };
  });

  return {
    _id: hls._id,
    selectedDate: new Date(hls.startAt),
    streamList,
    repeatSchedule: hls.repeat,
    urlLiveStream: hls.urlLiveStream,
    monetize: hls.enableEmbeddedAds,
    enableSrt: hls?.enableSrt,
    srtPort: hls?.srtPort,
    epgLink: hls?.epgLink,
    timezone: hls?.timezone ?? null,
    payPerView: hls.payPerView ?? false,
    price: hls.price ?? 0,
    adsTimer: {
      timer: {
        ...hls.adsTimer,
        adsByPlatform: hls.adsByPlatform
      },
    },
    title: hls.title,
    epgicon: hls.epgicon,
    clipboardMetadata: hls.clipboardMetadata,
    selectedItems:[],
    fillers: [
      ...hls.fillers.map((item) => {
        const { _id, fileName, description, duration, name, epnum, status, isLiveStream, c_k, isSRTExist, c_location, location, c_filename, storage, bucket, id1, id2 } =
        item.file?.[0] || {};

        return {
          id: _id,
          fileName,
          duration,
          c_k,
          c_location,
          isSRTExist,
          c_filename,
          location,
          storage: {
            cdnToken: storage?.[0]?.cdnToken,
            cdnUrl: storage?.[0]?.cdnUrl,
            title: storage?.[0]?.title,
          },
          bucket: {
            bucketName: bucket?.bucketName ?? bucket?.[0]?.bucketName,
          },
          key: item.key,
          originalName: name,
          description: description || '',
          epnum: epnum || '',
          status,
          isLiveStream,
          id1,
          id2
        }
      })
    ],
  };
};

export function moveTo(from, to, list) {
  list.splice(from, 0, list.splice(to, 1)[0]);
  return list;
}

export function swap(array, indexFirst, indexSecond) {
  array[indexFirst] = array.splice(indexSecond, 1, array[indexFirst])[0];
  return array;
}

export function getCountAdsInDuration(from, to, points, duration) {
  let counter = 0;
  let current_to = to;
  points.forEach(item => {
    if (item > from && item <= current_to) {
      counter ++;
      current_to += duration;
    }
  });

  return counter;
}

export const isExpired = (item, timezone, isActive = false) => {
  const rtmpStream = item?.rtmpStream;

  const now = momentTimezone.tz(timezone ?? 'PST8PDT');
  const nowToStr = now.format('YYYY-MM-DD HH:mm:ss');

  if (isActive && rtmpStream) {
    const { expireTime } = rtmpStream;
    const expiredDateUtc = momentTimezone.utc(expireTime);

    expiredDateUtc.add(now.utcOffset(), 'minutes');

    const expiredDateToStr = expiredDateUtc.format('YYYY-MM-DD HH:mm:ss');

    return nowToStr >= expiredDateToStr;
  }

  return false;
}

export const isEndSoon = (item, timezone, isActive = false) => {
  const rtmpStream = item?.rtmpStream;

  const now = momentTimezone.tz(timezone ?? 'PST8PDT');
  const nowDateToStr = now.format('YYYY-MM-DD');
  const nowTimeToStr = now.format('HH:mm:ss');

  if (isActive && rtmpStream) {
    const { expireTime } = rtmpStream;
    const expiredDateUtc =  momentTimezone.utc(expireTime);

    expiredDateUtc.add(now.utcOffset(), 'minutes');

    const expiredDateToStr = expiredDateUtc.format('YYYY-MM-DD');
    const expiredTimeToStr = expiredDateUtc.format('HH:mm:ss');

    return (nowDateToStr === expiredDateToStr) && (nowTimeToStr <= expiredTimeToStr);
  }

  return false;
}

export const getEndSoonTime = (item, timezone) => {
  const rtmpStream = item?.rtmpStream;
  const { expireTime } = rtmpStream;

  const now = momentTimezone.tz(timezone ?? 'PST8PDT');
  const expiredDateUtc =  momentTimezone.utc(expireTime);

  expiredDateUtc.add(now.utcOffset(), 'minutes');

  return expiredDateUtc.format('HH:mm:ss');
}