import { createReducer } from "@reduxjs/toolkit";
import {
  downloadReport,
  selectDate,
  selectPlatform,
} from "./reportAction";
import dayjs from "dayjs";

const initialState = {
  filters: {
    platform: null,
    start: dayjs().add(-1, 'week').format('YYYY-MM-DD 23:59:59'),
    end: dayjs().format('YYYY-MM-DD 00:00:00'),
  }
};

export const reportReducer = createReducer(initialState, ( builder ) => {
  builder
    .addCase(selectPlatform, (state, action) => {
      state.filters.platfotm = action.payload;
    })
    .addCase(selectDate, (state, action) => {
      state.filters.start = action.payload.start;
      state.filters.end = action.payload.end;
    })
    .addCase(downloadReport.fulfilled, (state, action) => {
      const href = window.URL.createObjectURL(new Blob([action.payload], {type: "octet/stream"}));
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'report.csv');
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(href);
    })
});