import { createAsyncThunk } from '@reduxjs/toolkit';
import API_ROUTES from '../../apiRoutes';
import API from '../../axiosApi';

export const getRoles = createAsyncThunk(
  'getRoles',
  async () => {
    const response = await API.get(`${API_ROUTES.roles}`);
    return response.data;
  }
);

export const getRoleById = createAsyncThunk(
  'getRoleById',
  async (id) => {
    const response = await API.get(`${API_ROUTES.roles}/${id}`);
    return response.data;
  }
);