import { createReducer } from '@reduxjs/toolkit';
import { DEFAULT_PAGINATION, LOADING_STATUS } from '../../helper/constant';

import {
    getRTMPs,
    changeRTMPPageSize,
    changeRTMPPage,
    createNewRTMP,
    deleteRTMP,
    editRTMP,
    getAllRTMP
} from './rtmpActions';

const initialState = {
    list: [],
    loadingStatus: LOADING_STATUS.idle,
    currentPage: 1,
    pageSize: DEFAULT_PAGINATION.limit,
    currentRTMP: {},
    alllist: []
};

export const rtmpReducer = createReducer(
    initialState,
    (builder) => {
        builder
            .addCase(getRTMPs.pending, (state) => {
                state.status = LOADING_STATUS.loading;
            })
            .addCase(getRTMPs.fulfilled, (state, action) => {
                state.status = LOADING_STATUS.success;
                const { rows, totalItems } = action.payload.data || {};
                state.list = rows;
                state.totalItems = totalItems;
            })
            .addCase(createNewRTMP.fulfilled, (_state, action) => {
                window.location.reload();
              })
            .addCase(deleteRTMP.fulfilled, (_state, action) => {
                window.location.reload();
              })
            .addCase(changeRTMPPageSize, (state, action) => {
                state.pageSize = action.payload;
                state.currentPage = 1;
              })
            .addCase(changeRTMPPage, (state, action) => {
                state.currentPage = action.payload;
            })
            .addCase(editRTMP.fulfilled, (state, action) => {
                window.location.reload();
              })
            .addCase(getAllRTMP.fulfilled, (state, action) => {
                const rows = action.payload.data || [];
                state.alllist = rows;
              })

    }
);