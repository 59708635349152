import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { API_ROUTES } from '../../helper/apiRoutes';
import API from '../../axiosAmsApi';

export const getRealTimeUsers = createAsyncThunk('getRealTimeUsers', async (payload) => {
  const response = await API.get( API_ROUTES.dashboard.realTime + '?' + new URLSearchParams(payload) );

  return response.data;
});

export const getDashboardCount = createAsyncThunk('getDashboardCount', async (payload) => {
  const response = await API.get( API_ROUTES.dashboard.count  + '?' + new URLSearchParams(payload) );

  return response.data;
});
export const getDashboardTime = createAsyncThunk('getDashboardTime', async (payload) => {
  const response = await API.get( API_ROUTES.dashboard.time  + '?' + new URLSearchParams(payload) );

  return response.data;
});

export const getViewersChart = createAsyncThunk('getViewersChart', async (payload) => {
  const response = await API.get( API_ROUTES.dashboard.viewersChart + '?' + new URLSearchParams(payload));

  return response.data;
});

export const getViewersByChannels = createAsyncThunk('getViewersByChannels', async (payload) => {
  const response = await API.get( API_ROUTES.dashboard.viewersByChannels + '?' + new URLSearchParams(payload));

  return response.data;
});

export const getViewersByCountries = createAsyncThunk('getViewersByCountries', async (payload) => {
  const response = await API.get( API_ROUTES.dashboard.viewersByCountry + '?' + new URLSearchParams(payload));

  return response.data;
});
export const getViewersByDevices = createAsyncThunk('getViewersByDevices', async (payload) => {
  const response = await API.get( API_ROUTES.dashboard.viewersByDevice + '?' + new URLSearchParams(payload));

  return response.data;
});

export const getWatchTimeChart = createAsyncThunk('getWatchTimeChart', async (payload) => {
  const response = await API.get( API_ROUTES.dashboard.watchTimeChart + '?' + new URLSearchParams(payload));

  return response.data;
});

export const getUsersChart = createAsyncThunk('getUsersChart', async (payload) => {
  const response = await API.get( API_ROUTES.dashboard.usersChart + '?' + new URLSearchParams(payload));

  return response.data;
});

export const getPlatformList = createAsyncThunk('getPlatformList', async (payload) => {
  let query = payload;
  query.query = '';
  const response = await API.get(API_ROUTES.filters + 'platform?' + new URLSearchParams(query));

  return response.data;
});

export const changeViewersPlatform = createAction('changeViewersPlatform', (platform) => {
  return {
    payload: platform,
  }
});

export const changeWatchTimePlatform = createAction('changeWatchTimePlatform', (platform) => {
  return {
    payload: platform,
  }
});

export const changeUsersPlatform = createAction('changeUsersPlatform', (platform) => {
  return {
    payload: platform,
  }
});

export const changeViewersChannel = createAction('changeViewersChannel', (channel) => {
  return {
    payload: channel,
  }
});

export const changeWatchTimeChannel = createAction('changeWatchTimeChannel', (channel) => {
  return {
    payload: channel,
  }
});

export const changeViewersPeriod = createAction('changeViewersPeriod', ({start, end}) => {
  return {
    payload: {start, end},
  }
});

export const changeWatchTimePeriod = createAction('changeWatchTimePeriod', ({start, end}) => {
  return {
    payload: {start, end},
  }
});

export const changeUserPeriod = createAction('changeUserPeriod', ({start, end}) => {
  return {
    payload: {start, end},
  }
});

export const changeUserCountry = createAction('changeUserCountry', (country) => {
  return {
    payload: country,
  }
})

export const setChannel = createAction('setChannel', function prepare(item) {
  return {
    payload: item,
  };
});