import {createAction, createAsyncThunk} from "@reduxjs/toolkit";
import API from "../../axiosApi";
import API_ROUTES from "../../apiRoutes";

export const updateMediaChannelItems = createAction('updateMediaChannelItems', function prepare(items) {
  return { payload: items };
});

export const changeMediaChannelByField = createAction('changeMediaChannelByField', function prepare(field, value) {
  return { payload: { field, value } };
});

export const selectMediaItem = createAction('selectMediaItem', function prepare(item) {
  return { payload: item };
});

export const deleteMediaItem = createAction('deleteMediaItem', function prepare(item) {
  return { payload: item };
});

export const changeMediaItemByField = createAction('changeMediaItemByField', function prepare(field, value) {
  return { payload: { field, value} };
});

export const resetCurrentMediaChannel = createAction('resetCurrentMediaChannel');

export const previewMediaItem = createAsyncThunk('previewMediaItem', async (item) => {
  if (!item) {
    return undefined;
  } else {
    const response = await API.post(API_ROUTES.fileLink, item);
    return response.data;
  }
});
export const srtMediaItem = createAction('srtMediaItem', function prepare(item) {
  return {
    payload: item,
  };
});


export const changeMrssPageSize = createAction('changeMrssPageSize', function prepare(pageSize) {
  return { payload: pageSize };
});

export const changeMrssPage = createAction('changeMrssPage', function prepare(page) {
  return { payload: page };
});

export const updateMediaItemInList = createAction('updateMediaItemInList', function (data) {
  return { payload: data }
});

export const selectRows = createAction('selectRows', function (selectedRowKeys) {
  return { payload: selectedRowKeys }
});

export const addingItems = createAction('addingItems', function (selectedRows) {
  return { payload: selectedRows }
});

export const resetSeriesThumbnail = createAction('resetSeriesThumbnail', function prepare(item) {
  return { payload: item };
});

export const selectedSeriesItem = createAction('selectedSeriesItem', function prepare(series) {
  return { payload: series };
});

export const changeSelectedSeriesItemOriginalName = createAction('changeSelectedSeriesItemOriginalName', function prepare(value) {
  return { payload: value };
});

export const changeSelectedSeriesItemDescription = createAction('changeSelectedSeriesItemDescription', function prepare(value) {
  return { payload: value };
});

export const changeSeasonActive = createAction('changeSeasonActive', function prepare(season) {
  return { payload: season };
});

export const resetSelectedSeriesItem = createAction('resetSelectedSeriesItem', function prepare(item) {
  return {};
});

export const addSeasonInSelectedSeriesItem = createAction('addSeasonInSelectedSeriesItem', function prepare(series) {
  return { payload: series };
});

export const deleteSeasonInSelectedSeriesItem = createAction('deleteSeasonInSelectedSeriesItem', function prepare(seriesItem) {
  return { payload: seriesItem };
});

export const addEpisodesToSeason = createAction('addEpisodesToSeason', function prepare(items) {
  return { payload: items };
});

export const editSelectedItemEpisodeNumber = createAction('editSelectedItemEpisodeNumber', function prepare(data) {
  return { payload: data };
});

export const addSeriesItem = createAction('addSeriesItem', function prepare(item) {
  return { payload: item };
});

export const updateSeriesItem = createAction('updateSeriesItem', function prepare(item) {
  return { payload: item };
});

export const addingEpisodes = createAction('addingEpisodes', function (selectedRows) {
  return { payload: selectedRows }
});

export const updateEpisodeInList = createAction('updateEpisodeInList', function (data) {
  return { payload: data }
});

export const deleteEpisode = createAction('deleteEpisode', function prepare(item) {
  return { payload: item };
});

export const saveMediaChannel = createAsyncThunk('saveMediaChannel', async (mediaChannel) => {
  const response = await API.post(`${ API_ROUTES.mrss }/save`, mediaChannel, {
    headers: {
      accept: 'application/json',
    },
  });

  return response.data;
});

export const getMediaChannel = createAsyncThunk('getMediaChannel', async (id) => {
  const response = await API.get(`${ API_ROUTES.mrss }/${id}`);

  return response.data;
});

export const getMediaChannels = createAsyncThunk('getMediaChannels', async (payload) => {
  const response = await API.get(
      `${ API_ROUTES.mrss }?limit=${ payload.limit }&page=${ payload.page }`
  );
  return response.data;
});

export const deleteMediaChannels = createAsyncThunk('deleteMediaChannels', async (ids) => {
  const response = await API.delete(
    API_ROUTES.mrss,
    {
      data: { ids },
    },
    {
      headers: {
        accept: 'application/json',
      },
    }
  );
  return response.data;
});

export const saveMediaItemChanging = createAsyncThunk('saveMediaItemChanging', async (data) => {
  const response = await API.post(`${ API_ROUTES.rename }`, data, {
    headers: {
      accept: 'application/json',
    },
  });

  return response.data;
});

export const uploadMediaChannelThumbnail = createAsyncThunk('uploadMediaChannelThumbnail', async (data) => {
  const response = await API.post(`${API_ROUTES.mrss}/upload-thumbnail`, data);

  return response.data;
});

export const uploadMediaItemThumbnail = createAsyncThunk('uploadMediaItemThumbnail', async (data) => {
  const response = await API.post(`${API_ROUTES.mrss}/upload-thumbnail`, data);

  return response.data;
});

export const changeMediaItemPositions = createAction('changeMediaItemPositions', function prepare(
    newId,
    oldId
) {
  return { payload: { newId, oldId } };
});

export const uploadSelectedSeriesItemThumbnail = createAsyncThunk('uploadSelectedSeriesItemThumbnail', async (data) => {
  const response = await API.post(`${API_ROUTES.mrss}/upload-thumbnail`, data);

  return response.data;
});