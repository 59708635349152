import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API_ROUTES from '../../apiRoutes';
import API from '../../axiosApi';

export const getChannelList = createAsyncThunk(
  'getChannelList',
  async (payload) => {
    const response = await API.get(
      `${API_ROUTES.channels}?limit=${payload.limit}&page=${payload.page}`
    );
    return response.data;
  }
);

export const getChannelDetail = createAsyncThunk(
  'getChannelDetail',
  async (id) => {
    const response = await API.get(`${API_ROUTES.channels}/${id}`);
    return response.data;
  }
);

export const getChannelDevices = createAsyncThunk(
  'getChannelDevices',
  async () => {
    const response = await API.get(`${API_ROUTES.channelDevices}`);
    return response.data;
  }
);

export const deleteChannel = createAsyncThunk('deleteChannel', async (id) => {
  const response = await API.delete(`${API_ROUTES.channels}/${id}`);
  return response.data;
});

export const deleteChannels = createAsyncThunk(
  'deleteChannels',
  async (ids) => {
    const response = await API.delete(
      API_ROUTES.channels,
      {
        data: { ids },
      },
      {
        headers: {
          accept: 'application/json',
        },
      }
    );
    return response.data;
  }
);

export const changeChannelPageSize = createAction(
  'changeChannelPageSize',
  function prepare(pageSize) {
    return {
      payload: pageSize,
    };
  }
);

export const changeChannelPage = createAction('changeChannelPage', function prepare(page) {
  return {
    payload: page,
  };
});

export const changeCurrentChannel = createAction(
  'changeCurrentChannel',
  function prepare(newChannel) {
    return {
      payload: newChannel,
    };
  }
);

export const updateChannel = createAsyncThunk(
  'updateChannel',
  async (storage) => {
    const id = storage._id;
    const response = await API.put(`${API_ROUTES.channels}/${id}`, storage, {
      headers: {
        accept: 'application/json',
      },
    });

    return response.data;
  }
);

export const createChannel = createAsyncThunk(
  'createChannel',
  async (storage) => {
    const response = await API.post(API_ROUTES.channels,
      storage,
      {
        headers: {
          accept: 'application/json'
        }
      }
    );
    return response.data
  }
)

export const resetCurrentChannel = createAction('resetCurrentChannel');
