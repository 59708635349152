import { DEFAULT_PAGINATION, LOADING_STATUS } from "../../helper/constant";
import { createReducer } from "@reduxjs/toolkit";
import {
  changeCurrentSrtChannel,
  changeSrtPage,
  changeSrtPageSize,
  deleteSrtChannels, getHlsChannels,
  getSrtChannel,
  getSrtChannels,
  resetCurrentSrtChannel,
  saveSrtChannel,
} from "./srtActions";

import { message } from "antd";
import ROUTE_PATH from "../../router/routePath";

const initialState = {
  list: [],
  loadingStatus: LOADING_STATUS.idle,
  currentPage: 1,
  pageSize: DEFAULT_PAGINATION.limit,
  channels: [],
  currentSrt: {
    id: undefined,
    channel: undefined,
    resolution: undefined,
    port: undefined,
  },
};

export const srtReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(changeSrtPageSize, (state, action) => {
      state.pageSize = action.payload;
      state.currentPage = 1;
    })
    .addCase(changeSrtPage, (state, action) => {
      state.currentPage = action.payload;
    })
    .addCase(getSrtChannels.pending, (state) => {
      state.loadingStatus = LOADING_STATUS.loading;
    })
    .addCase(getSrtChannels.fulfilled, (state, action) => {
      state.loadingStatus = LOADING_STATUS.success;
      const { rows, totalItems } = action.payload.data || {};
      state.list = rows;
      state.totalItems = totalItems;
    })
    .addCase(getSrtChannel.pending, (state) => {
      state.loadingStatus = LOADING_STATUS.loading;
    })
    .addCase(getSrtChannel.fulfilled, (state, action) => {
      state.loadingStatus = LOADING_STATUS.success;
      state.currentSrt = action.payload.data;
    })
    .addCase(resetCurrentSrtChannel, (state) => {
      state.currentSrt = {};
    })
    .addCase(getHlsChannels.fulfilled, (state, action) => {
      state.channels = action.payload.data || [];
    })
    .addCase(saveSrtChannel.fulfilled, (state, action) => {
      const id = action.payload?.data?._id;
      const currentLink = window.location.href;
      if (currentLink.includes('create')) {
        message.success('SRT created successfully');
        window.location.href = ROUTE_PATH.srtEditor.replace(':id', id);
      } else {
        message.success('SRT updated successfully');
      }
    })
    .addCase(changeCurrentSrtChannel, (state, action) => {
      const { name, value } = action.payload;
      state.currentSrt = {
        ...state.currentSrt,
        [name]: value,
      };
    })
    .addCase(deleteSrtChannels.fulfilled, (state, action) => {
      let newList = [];
      action.payload.data.ids.forEach((id) => {
        newList = state.list.filter((s) => s._id !== id);
      });
      state.list = newList;
    });
});