import { createReducer } from "@reduxjs/toolkit";
import {
  changeUserPeriod,
  resetUserFilters,
  applyUserFilters,
  updatePie,
} from "./userAmsAction";

const initialState = {
  data: [],
  period: 'day',
  filters: {
    channel_name: null,
    content_name: null,
    country: null,
    id1: null,
    id2: null,
  }
};

export const userAmsReducer = createReducer(initialState, (builder ) => {
  builder
    .addCase(changeUserPeriod, (state, action) => {
      state.period = action.payload;
    })
    .addCase(resetUserFilters, (state) => {
      state.filters = initialState.filters;
    })
    .addCase(applyUserFilters, (state, action) => {
      state.filters = action.payload;
    })
    .addCase(updatePie.fulfilled, (state, action) => {
      state.data = action.payload.rows;
    });
});