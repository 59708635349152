import { createReducer } from '@reduxjs/toolkit';
import { message } from 'antd';
import { DEFAULT_PAGINATION, LOADING_STATUS } from '../../helper/constant';
import ROUTE_PATH from '../../router/routePath';
import {
  changeCurrentAdvertisementBlock,
  changeAdvertisementPage,
  changeAdvertisementPageSize,
  createAdvertisementBlock,
  deleteAdvertisementBlocks,
  getAdvertisementBlockDetail,
  getAdvertisementBlockList,
  resetCurrentAdvertisementBlock,
  updateAdvertisementBlock,
  setAdsItems,
  deleteAdsItem
} from './advertisementBlockActions';

const initialState = {
  list: [],
  loadingStatus: LOADING_STATUS.idle,
  currentPage: 1,
  pageSize: DEFAULT_PAGINATION.limit,
  currentAdvertisementBlock: {},
};

export const advertisementBlockReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(getAdvertisementBlockList.pending, (state) => {
        state.status = LOADING_STATUS.loading;
      })
      .addCase(getAdvertisementBlockList.fulfilled, (state, action) => {
        state.status = LOADING_STATUS.success;
        const { rows, totalItems } = action.payload.data || {};
        state.list = rows;
        state.totalItems = totalItems;
      })
      .addCase(deleteAdvertisementBlocks.fulfilled, (state, action) => {
        let newList = [];
        action.payload.data.ids.forEach((id) => {
          newList = state.list.filter((s) => s._id !== id);
        });
        state.list = newList;
      })
      .addCase(changeAdvertisementPageSize, (state, action) => {
        state.pageSize = action.payload;
        state.currentPage = 1;
      })
      .addCase(changeAdvertisementPage, (state, action) => {
        state.currentPage = action.payload;
      })
      .addCase(getAdvertisementBlockDetail.fulfilled, (state, action) => {
        state.currentAdvertisementBlock = action.payload.data;
      })
      .addCase(changeCurrentAdvertisementBlock, (state, action) => {
        const { name, value } = action.payload;
        state.currentAdvertisementBlock = {
          ...state.currentAdvertisementBlock,
          [name]: value,
        };
      })
      .addCase(updateAdvertisementBlock.fulfilled, () => {
        message.success('Updated successfully!', 5);
      })
      .addCase(updateAdvertisementBlock.rejected, () => {
        message.error('Something went wrong!', 5);
      })
      .addCase(createAdvertisementBlock.fulfilled, (_state, action) => {
        const id = action.payload?.data?._id;
        window.location.href = ROUTE_PATH.advertisementBlockEditor.replace(
          ':id',
          id
        );
      })
      .addCase(createAdvertisementBlock.rejected, () => {
        message.error('Something went wrong!', 5);
      })
      .addCase(resetCurrentAdvertisementBlock, (state) => {
        state.currentAdvertisementBlock = {};
      })
      .addCase(setAdsItems, (state, action) => {
        if (state.currentAdvertisementBlock.items) {
          state.currentAdvertisementBlock = {
            ...state.currentAdvertisementBlock,
            items: [...state.currentAdvertisementBlock.items, ...action.payload]
          };
        } else {
          state.currentAdvertisementBlock = {
            ...state.currentAdvertisementBlock,
            items: action.payload
          };
        }
      })
      .addCase(deleteAdsItem, (state, action) => {
        const filteredItems = state.currentAdvertisementBlock.items.filter(o => o._id !== action.payload._id)
        state.currentAdvertisementBlock = {
          ...state.currentAdvertisementBlock,
          items: filteredItems
        };
      })
  }
);
