import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API_ROUTES from '../../apiRoutes';
import API from '../../axiosApi';


export const changeStreamList = createAction('changeStreamList', function prepare(
    streamList,
    timeOffset
) {
  return { payload: { streamList, timeOffset } };
});

