import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API_ROUTES from '../../apiRoutes';
import API from '../../axiosApi';

export const getStorageList = createAsyncThunk(
  'getStorageList',
  async (payload) => {
    const response = await API.get(
      `${API_ROUTES.storages}?limit=${payload.limit}&page=${payload.page}`
    );
    return response.data;
  }
);

export const getAllStorageList = createAsyncThunk(
  'getAllStorageList',
  async () => {
    const response = await API.get(
      `${API_ROUTES.storages}?limit=-1&page=-1`
    );
    return response.data;
  }
);

export const getStorageDetail = createAsyncThunk(
  'getStorageDetail',
  async (id) => {
    const response = await API.get(`${API_ROUTES.storages}/${id}`);
    return response.data;
  }
);

export const deleteStorage = createAsyncThunk('deleteStorage', async (id) => {
  const response = await API.delete(`${API_ROUTES.storages}/${id}`);
  return response.data;
});

export const deleteStorages = createAsyncThunk(
  'deleteStorages',
  async (ids) => {
    const response = await API.delete(
      API_ROUTES.storages,
      {
        data: { ids },
      },
      {
        headers: {
          accept: 'application/json',
        },
      }
    );
    return response.data;
  }
);

export const changePageSize = createAction(
  'changePageSize',
  function prepare(pageSize) {
    return {
      payload: pageSize,
    };
  }
);

export const changePage = createAction('changePage', function prepare(page) {
  return {
    payload: page,
  };
});

export const changeCurrentStorage = createAction(
  'changeCurrentStorage',
  function prepare(newStorage) {
    return {
      payload: newStorage,
    };
  }
);

export const updateStorage = createAsyncThunk(
  'updateStorage',
  async (storage) => {
    const id = storage._id;
    const response = await API.put(`${API_ROUTES.storages}/${id}`, storage, {
      headers: {
        accept: 'application/json',
      },
    });
    return response.data;
  }
);

export const createStorage = createAsyncThunk(
  'createStorage',
  async (storage) => {
    const response = await API.post(API_ROUTES.storages, storage, {
      headers: {
        accept: 'application/json',
      },
    });
    return response.data;
  }
);

export const resetCurrentStorage = createAction('resetCurrentStorage');
