import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API from "../../axiosAmsApi";
import { API_ROUTES } from "../../helper/apiRoutes";

export const changePeriod = createAction('changePeriod', function prepare(period) {
  return {
    payload: period,
  };
});

export const resetFilters = createAction('resetFilters');
export const resetSearchData = createAction('resetSearchData');

export const applyFilters = createAction('applyFilters', function prepare(filters) {
  return {
    payload: filters,
  }
});

export const updateViewersChart = createAsyncThunk('updateViewersChart',async ({ period, filters }) => {
  const searchParams = getSearchParams(period, filters);
  const response = await API.get(API_ROUTES.analytics.viewers + '?' + searchParams.toString());

  return response.data;
});


export const updateWatchTimeChart = createAsyncThunk('updateWatchTimeChart',async ({ period, filters }) => {
  const searchParams = getSearchParams(period, filters);
  const response = await API.get(API_ROUTES.analytics.watchTime + '?' + searchParams.toString());

  return response.data;
});

const getSearchParams = (period, filters) => {
  const searchParams = new URLSearchParams({ period });

  Object.entries(filters).forEach((filter) => {
    let field = `filters[${filter[0]}][]`;
    if (filter[1]) {
      filter[1].forEach((data) => {
        searchParams.append(field, data);
      })
    }
  });

  return searchParams;
}