const API_ROUTES = {
  // baseUrl: 'http://34.41.107.250/api/v2',
  baseUrl: 'https://api.showfer.com/api',
  // baseUrl: 'http://localhost:8000/api/v2',
  login: 'auth/login',
  users: 'users',
  bucket: 'bucket',
  storages: 'storages',
  categories: 'categories',
  channels: 'channels',
  channelDevices: 'channels/devices',
  conversion: 'convert-items',
  roles: 'roles',
  hls: 'channel-stream',
  files: 'convert-items/files',
  fillers: 'convert-items/fillers',
  changeFile: 'convert-items/change',
  fileLink: 'back-blaze/url',
  fileLinkByName: 'back-blaze/url_by_name',
  advertisementBlock: 'advertisement-blocks',
  rename: 'convert-items/change',
  rtmps: 'rtmps',
  rtmp: 'rtmp',
  broadcast: 'broadcast',
  adGroup: 'ad-group',
  mrss: 'media-stream',
  srt: 'srt',
  vtt: 'vtt'
}

export default API_ROUTES;
