import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API_ROUTES from '../../apiRoutes';
import API from '../../axiosApi';

export const getAdvertisementBlockList = createAsyncThunk(
  'getAdvertisementBlockList',
  async (payload) => {
    const response = await API.get(
      `${API_ROUTES.advertisementBlock}?limit=${payload.limit}&page=${payload.page}`
    );
    return response.data;
  }
);

export const getAdvertisementBlockDetail = createAsyncThunk(
  'getAdvertisementBlockDetail',
  async (id) => {
    const response = await API.get(`${API_ROUTES.advertisementBlock}/${id}`);
    return response.data;
  }
);

export const deleteAdvertisementBlock = createAsyncThunk('deleteAdvertisementBlock', async (id) => {
  const response = await API.delete(`${API_ROUTES.advertisementBlock}/${id}`);
  return response.data;
});

export const deleteAdvertisementBlocks = createAsyncThunk(
  'deleteAdvertisementBlocks',
  async (ids) => {
    const response = await API.delete(
      API_ROUTES.advertisementBlock,
      {
        data: { ids },
      },
      {
        headers: {
          accept: 'application/json',
        },
      }
    );
    return response.data;
  }
);

export const changeAdvertisementPageSize = createAction(
  'changeAdvertisementPageSize',
  function prepare(pageSize) {
    return {
      payload: pageSize,
    };
  }
);

export const changeAdvertisementPage = createAction('changeAdvertisementPage', function prepare(page) {
  return {
    payload: page,
  };
});

export const changeCurrentAdvertisementBlock = createAction(
  'changeCurrentAdvertisementBlock',
  function prepare(newAdvertisementBlock) {
    return {
      payload: newAdvertisementBlock,
    };
  }
);

export const updateAdvertisementBlock = createAsyncThunk(
  'updateAdvertisementBlock',
  async (advertisementBlock) => {
    const id = advertisementBlock._id;
    const response = await API.put(`${API_ROUTES.advertisementBlock}/${id}`, advertisementBlock, {
      headers: {
        accept: 'application/json',
      },
    });

    return response.data;
  }
);

export const createAdvertisementBlock = createAsyncThunk(
  'createAdvertisementBlock',
  async (storage) => {
    const response = await API.post(API_ROUTES.advertisementBlock, storage, {
      headers: {
        accept: 'application/json',
      },
    });
    return response.data;
  }
);

export const resetCurrentAdvertisementBlock = createAction('resetCurrentAdvertisementBlock');

export const setAdsItems = createAction('setAdsItems', function prepare(items) {
  return {
    payload: items,
  };
});

export const deleteAdsItem = createAction('deleteAdsItem', function prepare(item) {
  return {
    payload: item,
  };
});
