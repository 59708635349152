import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API_ROUTES from '../../apiRoutes';
import API from '../../axiosApi';

export const getCategoryList = createAsyncThunk(
  'getCategoryList',
  async (payload) => {
    const response = await API.get(
      `${API_ROUTES.categories}?limit=${payload.limit}&page=${payload.page}`
    );
    return response.data;
  }
);

export const getCategoryDetail = createAsyncThunk(
  'getCategoryDetail',
  async (id) => {
    const response = await API.get(`${API_ROUTES.categories}/${id}`);
    return response.data;
  }
);

export const deleteCategory = createAsyncThunk('deleteCategory', async (id) => {
  const response = await API.delete(`${API_ROUTES.categories}/${id}`);
  return response.data;
});

export const deleteCategories = createAsyncThunk(
  'deleteCategories',
  async (ids) => {
    const response = await API.delete(
      API_ROUTES.categories,
      {
        data: { ids },
      },
      {
        headers: {
          accept: 'application/json',
        },
      }
    );
    return response.data;
  }
);

export const changeCategoryPageSize = createAction(
  'changeCategoryPageSize',
  function prepare(pageSize) {
    return {
      payload: pageSize,
    };
  }
);

export const changeCategoryPage = createAction('changeCategoryPage', function prepare(page) {
  return {
    payload: page,
  };
});

export const changeCurrentCategory = createAction(
  'changeCurrentCategory',
  function prepare(newCategory) {
    return {
      payload: newCategory,
    };
  }
);

export const updateCategory = createAsyncThunk(
  'updateCategory',
  async (storage) => {
    const id = storage._id;
    const response = await API.put(`${API_ROUTES.categories}/${id}`, storage, {
      headers: {
        accept: 'application/json',
      },
    });

    return response.data;
  }
);

export const createCategory = createAsyncThunk(
  'createCategory',
  async (storage) => {
    const response = await API.post(API_ROUTES.categories, storage, {
      headers: {
        accept: 'application/json',
      },
    });
    return response.data;
  }
);

export const resetCurrentCategory = createAction('resetCurrentCategory');
