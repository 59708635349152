import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API_ROUTES from '../../apiRoutes';
import API from '../../axiosApi';

export const getConversionList = createAsyncThunk(
  'getConversionList',
  async ({ limit, page, buckets, statuses, fileName }) => {
    const response = await API.get(
      `${API_ROUTES.conversion}?limit=${limit}&page=${page}`,
      { params: { buckets, statuses, fileName } },
    );
    return response.data;
  }
);

export const reimportItem = createAsyncThunk('reimportItem', async (item) => {
  await API.post(`${API_ROUTES.conversion}/reimport/${item.id}`);

  return item;
})

export const addCCFile = createAsyncThunk('addCCFile', async (data) => {
  await API.post(`${API_ROUTES.conversion}/addcc/${data.id}`, {data});

  return data;
})

export const changeItemPageSize = createAction(
  'changeItemPageSize',
  function prepare(pageSize) {
    return {
      payload: pageSize,
    };
  }
);

export const changeStatusFilter = createAction('changeStatusFilter', function prepare(statuses) {
  return {
    payload: statuses,
  }
})

export const changeItemPage = createAction('changeItemPage', function prepare(page) {
  return {
    payload: page,
  };
});

export const changeSelectedBuckets = createAction(
  'changeSelectedBuckets',
  function prepare(buckets) {
    return {
      payload: buckets,
    };
  }
);
