import { createReducer } from '@reduxjs/toolkit';
import { DEFAULT_PAGINATION, LOADING_STATUS } from '../../helper/constant';
import {
    changeFillerFilter, getFillers, previewItem, previewItemClose,
    addFiller,
    removeFillerApi,
    removeFiller, srtItem
} from './fillerActions';

const initialState = {
  list: [],
  loadingStatus: LOADING_STATUS.idle,
  filter: {
    limit: 100,
    page: DEFAULT_PAGINATION.page,
    name: '',
    sortName: 'asc',
    sortDuration: 'asc',
  },
    currentFillers: {
        previewItem: undefined,
        srtItem: undefined,
        fillers: [],
    },

    totalItems: 0,
};

export const fillerReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getFillers.pending, (state) => {
      state.loadingStatus = LOADING_STATUS.loading;
    })
    .addCase(getFillers.fulfilled, (state, action) => {
      const {rows, totalItems} = action.payload.data;
      state.loadingStatus = LOADING_STATUS.success;
      state.list = rows;
      state.currentFillers.fillers = rows;
      state.totalItems = totalItems;
    })
    .addCase(changeFillerFilter, (state, action) => {
      state.filter = action.payload;
    })
    .addCase(previewItem.fulfilled, (state, action) => {
        state.currentFillers.previewItem = action.payload;
     })
    .addCase(previewItemClose, (state, action) => {
        state.currentFillers.previewItem = action.payload;
     })
      .addCase(addFiller, (state, action) => {
          if (!state.currentFillers.fillers) {
              state.currentFillers.fillers = [];
          }

          let existEl = state.currentFillers.fillers.find(item => item._id === action.payload._id);
          if (!existEl) {
              state.currentFillers.fillers.push(action.payload);
          }
      })
      .addCase(removeFiller, (state, action) => {
          console.log(action.payload);
          console.log(state.currentFillers.fillers);
          state.currentFillers.fillers = state.currentFillers.fillers.filter((item) => item._id !== action.payload._id);
      })
      .addCase(srtItem, (state, action) => {
          state.currentFillers.srtItem = action.payload;
      })
      .addCase(removeFillerApi.fulfilled, (state, action) => {

          state.currentFillers.fillers = state.currentFillers.fillers.filter((item) => item._id !== action.payload);
      })

});
