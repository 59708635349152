import { createReducer } from '@reduxjs/toolkit';
import { message } from 'antd';
import { DEFAULT_PAGINATION, LOADING_STATUS } from '../../helper/constant';
import ROUTE_PATH from '../../router/routePath';
import {
  changeCurrentChannel,
  changeChannelPage,
  changeChannelPageSize,
  createChannel,
  deleteChannels,
  getChannelDetail,
  getChannelDevices,
  getChannelList,
  resetCurrentChannel,
  updateChannel,
} from './channelActions';

const initialState = {
  list: [],
  loadingStatus: LOADING_STATUS.idle,
  currentPage: 1,
  pageSize: DEFAULT_PAGINATION.limit,
  currentChannel: {},
  devices: [],
};

export const channelReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getChannelList.pending, (state) => {
      state.status = LOADING_STATUS.loading;
    })
    .addCase(getChannelList.fulfilled, (state, action) => {
      state.status = LOADING_STATUS.success;
      const { rows, totalItems } = action.payload.data || {};
      state.list = rows;
      state.totalItems = totalItems;
    })
    .addCase(deleteChannels.fulfilled, (state, action) => {
      let newList = [];
      action.payload.data.ids.forEach((id) => {
        newList = state.list.filter((s) => s._id !== id);
      });
      state.list = newList;
    })
    .addCase(changeChannelPageSize, (state, action) => {
      state.pageSize = action.payload;
      state.currentPage = 1;
    })
    .addCase(changeChannelPage, (state, action) => {
      state.currentPage = action.payload;
    })
    .addCase(getChannelDetail.fulfilled, (state, action) => {
      state.currentChannel = action.payload.data;
    })
    .addCase(changeCurrentChannel, (state, action) => {
      const { name, value } = action.payload;
      state.currentChannel = {
        ...state.currentChannel,
        [name]: value,
      };
    })
    .addCase(updateChannel.fulfilled, () => {
      message.success('Updated successfully!', 5);
    })
    .addCase(updateChannel.rejected, () => {
      message.error('Something went wrong!', 5);
    })
    .addCase(getChannelDevices.fulfilled, (state, action) => {
      state.devices = action.payload.data.map((device) => {
        return {
          label: device,
          value: device,
        };
      });
    })
    .addCase(createChannel.fulfilled, (_state, action) => {
      const id = action.payload?.data?._id;
      window.location.href = ROUTE_PATH.channelEditor.replace(':id', id);
    })
    .addCase(createChannel.rejected, () => {
      message.error('Something went wrong!', 5);
    })
    .addCase(resetCurrentChannel, (state) => {
      state.currentChannel = {};
    });
});
