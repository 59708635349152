import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API_ROUTES from '../../apiRoutes';
import API from '../../axiosApi';

export const getFiles = createAsyncThunk('getFileList', async (filters) => {
  const response = await API.get(API_ROUTES.files, { params: filters });
  return response.data;
});

export const deleteFile = createAsyncThunk('deleteFile', async (data) => {
  const response = await API.delete(API_ROUTES.conversion + '/delete', { data });
  return response.data;
});

export const changeFileFilter = createAction(
  'changeFileFilter',
  function prepare(filter) {
    return {
      payload: filter,
    };
  }
);

export const selectFiles = createAction('selectFiles', function prepare(data) {
  return { payload: data };
});
