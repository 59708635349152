import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../assets/images/logo.png';
import ROUTE_PATH from '../../router/routePath';
import SidebarLink from './SidebarLink';
import { getAuthor } from '../../store/user/userActions';
import { authorSelector } from '../../store/user/userSelector';

import './style.scss';

const LEFT_MENU_DATA = [
  {
    routePath: ROUTE_PATH.hls,
    title: 'Scheduler',
    role: ['Admin', 'Customer', 'General', 'Stormcloud'],
  },
  {
    routePath: ROUTE_PATH.adStorm,
    title: 'Ad Storm',
    role: ['Admin', 'Stormcloud'],
  },
  {
    routePath: ROUTE_PATH.provision,
    title: 'Provision',
    role: ['Admin', 'Stormcloud']
  },
];

const RIGHT_MENU_DATA = [
  {
    title: 'CMS',
    role: ['Admin', 'Customer', 'General', 'Stormcloud'],
    items: [
      {
        routePath: ROUTE_PATH.rtmp,
        title: 'Live Stream',
        role: ['Admin', 'Customer', 'General', 'Stormcloud']
      },
      {
        routePath: ROUTE_PATH.mrss,
        title: 'VOD',
        role: ['Admin', 'Customer', 'General', 'Stormcloud']
      },
      {
        routePath: ROUTE_PATH.liveStream,
        title: 'Transcoding',
        role: ['Admin', 'Customer', 'General', 'Stormcloud']
      },
      {
        routePath: ROUTE_PATH.uploadFile,
        title: 'File Manager',
        role: ['Admin', 'Customer', 'General', 'Stormcloud']
      },
      {
        routePath: ROUTE_PATH.advertisementBlock,
        title: 'Ads',
        role: ['Admin', 'Customer', 'General', 'Stormcloud']
      },
      {
        routePath: ROUTE_PATH.fillers,
        title: 'Fillers',
        role: ['Admin', 'General']
      },
      {
        routePath: ROUTE_PATH.analytics,
        title: 'Analytics',
        role: ['Admin', 'Customer', 'General', 'Stormcloud']
      },
    ]
  },
  {
    routePath: ROUTE_PATH.bucket,
    title: 'Buckets',
    role: ['Admin', 'General']
  },
  {
    routePath: ROUTE_PATH.users,
    title: 'Users',
    role: ['Admin']
  },
];

const SideBar = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const author = useSelector(authorSelector);

  const [leftMenuData, setLeftMenuData] = useState(LEFT_MENU_DATA);
  const [rightMenuData, setRightMenuData] = useState(RIGHT_MENU_DATA);

  useEffect(() => {
    const authenticate = async () => {
      const userToken = localStorage.getItem("token");
      if (!userToken) {
        navigate('/login');
      } else {
        dispatch(getAuthor());
      }
    }

    authenticate()
  }, [navigate, dispatch]);

  useEffect(() => {
    let leftMenu = [...LEFT_MENU_DATA];
    let rightMenu = [...RIGHT_MENU_DATA]
    const userRole = author?.role?.[0]?.name;
    if ( userRole !== 'Admin') {
      leftMenu = leftMenu.filter(object => {
        return object.role.includes(userRole);
      });
      rightMenu = rightMenu.filter(object => {
        return object.role.includes(userRole);
      });
    }

    setLeftMenuData(leftMenu);
    setRightMenuData(rightMenu);
  }, [author]);

  const handleClickLogout = useCallback(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("author");
    navigate('/login');
  }, [navigate]);




  return (
    <div className="showfer-admin">
      <div className="header">
        <Link className="header-home-link" to={ROUTE_PATH.home}>
          <img src={logo} alt="showfer" className="header-logo" />
        </Link>
        <div className="header-menu">
          <div className="header-menu-left">

            {leftMenuData.map((sideBar) => {
              const { routePath, title, items } = sideBar;
              if (ROUTE_PATH.provision === routePath) {
                return <a className={'header-menu-item'} href={ routePath }>{ title }</a>
              }
              return (
                  <SidebarLink
                      to={ routePath }
                      title={ title }
                      key={ routePath }
                      items={ items }
                  />
              );
            })}
          </div>
          <div className="header-menu-right">
            {rightMenuData.map((sideBar) => {
              const { routePath, title, items } = sideBar;
              return (
                  <SidebarLink
                      to={ routePath }
                      title={ title }
                      key={ routePath }
                      items={ items }
                  />
              );
            })}

            <a href="#" className="header-menu-item" onClick={ handleClickLogout }>Logout</a>
          </div>
        </div>
      </div>
      <div className="body">{ children }</div>
    </div>
  );
};

export default SideBar;
