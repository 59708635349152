import { createReducer } from "@reduxjs/toolkit";
import {
  changeUserCountry,
  changeUserPeriod,
  changeUsersPlatform,
  changeViewersChannel, changeViewersPeriod,
  changeViewersPlatform,
  changeWatchTimeChannel, changeWatchTimePeriod,
  changeWatchTimePlatform, clearDashboardCount, clearDashboardTime,
  getDashboardCount,
  getDashboardTime,
  getPlatformList,
  getRealTimeUsers,
  getUsersChart, getViewersByChannels, getViewersByCountries, getViewersByDevices,
  getViewersChart,
  getWatchTimeChart, setChannel
} from "./dashboardAction";
import dayjs from 'dayjs';
import {getHlsList} from "../hls/hlsActions";
import {LOADING_STATUS} from "../../helper/constant";

const initialState = {
  info: {
    channel: '',
    deviceColors: [
      {
        name: 'Mobile',
        color: '#2a52a4',
        className: 'color-blue',
        count: 0
      },
      {
        name: 'Web App',
        color: '#007e29',
        className: 'color-green',
        count: 0
      },
      {
        name: 'TV OS',
        color: '#d92b55',
        className: 'color-red',
        count: 0
      },
      {
        name: 'Tablet',
        color: '#e8de13',
        className: 'color-yellow',
        count: 0
      },
      {
        name: 'Watch',
        color: '#ca03ff',
        className: 'color-pink',
        count: 0
      },
    ],
    platforms: [],
    viewers: {
      platform: '',
      loading: true,
      count: 0,
      changing: 0,
      chart: [],
      top: [],
      // channel: '',
      country: [],
      devices: [],
      period: {
        start: dayjs().add(-1, 'week').format('YYYY-MM-DD'),
        end: dayjs().format('YYYY-MM-DD'),
      },
    },
    watchTime: {
      platform: '',
      loading: true,
      count: 0,
      changing: 0,
      chart: [],
      top: [],
      channel: '',
      period: {
        start: dayjs().add(-1, 'week').format('YYYY-MM-DD'),
        end: dayjs().format('YYYY-MM-DD'),
      },
    },
    allWatchTime: {
      platform: '',
      loading: true,
      count: 0,
      changing: 0,
      chart: [],
      top: [],
      channel: '',
      period: {
        start: dayjs().add(-1, 'week').format('YYYY-MM-DD'),
        end: dayjs().format('YYYY-MM-DD'),
      },
    },
    impression: {
      platform: '',
      loading: true,
      count: 0,
      changing: 0,
      chart: [],
      top: [],
      channel: '',
      period: {
        start: dayjs().add(-1, 'week').format('YYYY-MM-DD'),
        end: dayjs().format('YYYY-MM-DD'),
      },
    },
    clicks: {
      platform: '',
      loading: true,
      count: 0,
      changing: 0,
      chart: [],
      top: [],
      channel: '',
      period: {
        start: dayjs().add(-1, 'week').format('YYYY-MM-DD'),
        end: dayjs().format('YYYY-MM-DD'),
      },
    },
    users: {
      platform: '',
      loading: true,
      count: 0,
      chart: [],
      country: 'United States',
      cities: [],
      period: {
        start: dayjs().add(-1, 'week').format('YYYY-MM-DD'),
        end: dayjs().format('YYYY-MM-DD'),
      },
    }
  }
};

export const dashboardReducer = createReducer(initialState, ( builder ) => {
  builder
    .addCase(getRealTimeUsers.fulfilled, (state, action) => {
      state.info.users.count = action.payload.count;
    })
    .addCase(getDashboardCount.pending, (state) => {
      state.info.viewers.count = -1;
      state.info.impression.count = -1;
      state.info.clicks.count = -1;

      state.info.viewers.loading = true;
      state.info.impression.loading = true;
      state.info.clicks.loading = true;

      state.info.viewers.changing = 0;
      state.info.impression.changing = 0
      state.info.clicks.changing = 0;
    })
    .addCase(getDashboardCount.fulfilled, (state, action) => {
      state.info.viewers.count = Math.round(action.payload.viewers.count);
      state.info.impression.count = Math.round(action.payload?.impression?.count)
      state.info.clicks.count = action.payload?.clicks.count;

      state.info.viewers.changing = action.payload.viewers.changing <= 999 ? action.payload.viewers.changing : 0;
      state.info.impression.changing = action.payload?.impression?.changing <= 999 ? action.payload.impression.changing : 0;
      state.info.clicks.changing = action.payload?.clicks.changing <= 999 ? action.payload.clicks.changing : 0;


      state.info.viewers.loading = false;
      state.info.impression.loading = false;
      state.info.clicks.loading = false;
    })
    .addCase(getDashboardTime.pending, (state) => {
      state.info.watchTime.count = -1;
      state.info.allWatchTime.count = -1;

      state.info.watchTime.loading = true;
      state.info.allWatchTime.loading = true;

      state.info.watchTime.changing = 0;
      state.info.allWatchTime.changing = 0
    })
    .addCase(getDashboardTime.fulfilled, (state, action) => {
      state.info.watchTime.count = action.payload.time.count;
      state.info.allWatchTime.count = action.payload?.all_time?.count

      state.info.watchTime.changing = action.payload.time.changing <= 999 ? action.payload.time.changing : 0;
      state.info.allWatchTime.changing = action.payload?.all_time?.changing <= 999 ? action.payload?.all_time?.changing : 0;

      state.info.watchTime.loading = false;
      state.info.allWatchTime.loading = false;
    })
    .addCase(getPlatformList.fulfilled, (state, action) => {
      state.info.platforms = action.payload.rows.map((row) => {
        return {
          label: row === '' ? 'All' : row,
          value: row,
        }
      })

      let allItemExist = false;
      state.info.platforms.map((row) => {
        if(row.label === 'All') {
          allItemExist = true;
        }
        return row;
      })

      if(allItemExist === false) {
        state.info.platforms.unshift({
          label: 'All',
          value: ''
        });
      }
    })
    .addCase(getViewersChart.pending, (state) => {
      state.info.viewers.loading = true;
    })
    .addCase(getWatchTimeChart.pending, (state) => {
      state.info.watchTime.loading = true;
    })
    .addCase(getUsersChart.pending, (state) => {
      state.info.users.loading = true;
    })
    .addCase(getViewersChart.fulfilled, (state, action) => {
      let data = [
        [
          { type: "date" },
          'Viewers',
        ],
      ];

      const startDate = dayjs(state.info.viewers.period.start.toString());
      const diff = dayjs(state.info.viewers.period.end.toString()).diff(startDate, 'days');
      for (let i = 0; i <= diff; i++) {
        const newDate = startDate.add(i, 'days').format('YYYYMMDD');
        const element = action.payload.data.find(row => {
          const notFormatDate = row.date.toString();

          return notFormatDate === newDate;
        });

        const date = newDate.substr(0, 4) + '-' + newDate.substr(4, 2) + '-' + newDate.substr(6, 2);
        const count = element ? element.count : 0;

        data.push([
          date,
          count,
        ])
      }

      state.info.viewers.chart = data;
      state.info.viewers.loading = false;
    })
    .addCase(getViewersByChannels.fulfilled, (state, action) => {
      state.info.viewers.top = action.payload.top.map((row, index) => {
        row.key = index;
        row.count = row.count > 1000 ? Math.round(row.count / 1000) + 'K' : row.count;
        return row;
      });
    })
    .addCase(getViewersByCountries.fulfilled, (state, action) => {
      state.info.viewers.country = action.payload.top.map((row, index) => {
        row.key = index;
        row.count = row.count > 1000 ? Math.round(row.count / 1000) + 'K' : row.count;
        return row;
      });
    })
    .addCase(getViewersByDevices.fulfilled, (state, action) => {
      action.payload.devices.map((row, index) => {
      state.info.viewers.devices = state.info.deviceColors.map((deviceRow, deviceIndex) => {
            if(deviceRow.name === row.device) {
              deviceRow.count = row.count;
            }
            return deviceRow;
        });
      });
    })
    .addCase(getWatchTimeChart.fulfilled, (state, action) => {
      let data = [
        [
          { type: "date" },
          'Avg Watch Time',
        ],
      ];

      action.payload.data.forEach((row) => {
        const notFormatDate = row.date.toString();
        const date = notFormatDate.substr(0, 4) + '-' + notFormatDate.substr(4, 2) + '-' + notFormatDate.substr(6, 2);

        data.push([
          date,
          row.time,
        ])
      });

      state.info.watchTime.top = action.payload.top.map((row, index) => {
        row.key = index;
        row.time = row.time + 'm';
        return row;
      });
      state.info.watchTime.chart = data;
      state.info.watchTime.loading = false;
    })
    .addCase(getUsersChart.fulfilled, (state, action) => {
      state.info.users.chart = [["Country", "Users"]].concat(action.payload.data.map((row) => {
        return [
          row.country,
          row.count
        ];
      }));

      state.info.users.cities = action.payload.cities.map((row, index) => {
        row.key = index;
        row.count = row.count > 1000 ? Math.round(row.count / 1000) + 'K' : row.count;
        return row;
      });

      state.info.users.loading = false;
    })
    .addCase(changeViewersPlatform, (state, action) => {
      state.info.viewers.platform = action.payload;
    })
    .addCase(changeWatchTimePlatform, (state, action) => {
      state.info.watchTime.platform = action.payload;
    })
    .addCase(changeUsersPlatform, (state, action) => {
      state.info.users.platform = action.payload;
    })
    .addCase(changeViewersChannel, (state, action) => {
      state.info.viewers.channel = action.payload;
    })
    .addCase(changeWatchTimeChannel, (state, action) => {
      state.info.watchTime.channel = action.payload;
    })
    .addCase(changeViewersPeriod, (state, action) => {
      state.info.viewers.period = action.payload;
    })
    .addCase(changeWatchTimePeriod, (state, action) => {
      state.info.watchTime.period = action.payload;
    })
    .addCase(changeUserPeriod, (state, action) => {
      state.info.users.period = action.payload;
    })
    .addCase(changeUserCountry, (state, action) => {
      state.info.users.country = action.payload;
    })
    .addCase(setChannel, (state, action) => {

      if(state.info.viewers.loading === false && state.info.viewers.count > 0) {
        if(action.payload?.channel_id && action.payload?.channel_id === state.info.channel) {
          state.info.channel = '';
        } else {
          state.info.channel = action.payload?.channel_id ?? '';
        }
      }

    })
});