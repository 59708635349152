export const API_ROUTES = {
  login: '/login',

  dashboard: {
    realTime: '/api/dashboard/real-time',
    count: '/api/dashboard/count',
    time: '/api/dashboard/time',
    viewersChart: '/api/dashboard/chart/viewers',
    viewersByChannels: '/api/dashboard/viewers/channel',
    viewersByCountry: '/api/dashboard/viewers/country',
    viewersByDevice: '/api/dashboard/viewers/device',
    watchTimeChart: '/api/dashboard/chart/watch-time',
    usersChart: '/api/dashboard/chart/users',
  },

  filters: '/api/filter/',

  analytics: {
    viewers: '/api/analytics/viewers',
    watchTime: '/api/analytics/watch-time',
  },

  user: '/api/users',
  report: '/api/report',
  options: '/api/options',
  avgWatch: '/api/channels/avg_watch',
};