import React from "react";
import logo from '../../assets/images/logo.png';

import './style.scss';

const Home = () => {
    return (
        <div className={'dashboard'}>
            <img className="dashboard-img" src={logo} alt="logo" />
        </div>
    );
};

export default Home;
