import { createReducer } from '@reduxjs/toolkit';
import { DEFAULT_PAGINATION, LOADING_STATUS } from '../../helper/constant';
import {
  changeItemPage,
  changeItemPageSize,
  changeSelectedBuckets,
  changeStatusFilter,
  getConversionList, 
  reimportItem,
  addCCFile
} from './conversionActions';

const initialState = {
  list: [],
  loadingStatus: LOADING_STATUS.idle,
  currentPage: 1,
  pageSize: DEFAULT_PAGINATION.limit,
  statuses: [
    'processing',
    'queue',
    'success',
  ],
  totalItems: 0,
  selectedBuckets: [],
};

export const conversionReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getConversionList.pending, (state) => {
      state.loadingStatus = LOADING_STATUS.loading;
    })
    .addCase(getConversionList.fulfilled, (state, action) => {
      state.loadingStatus = LOADING_STATUS.success;
      const { rows, totalItems } = action.payload.data || {};
      state.list = rows;
      state.totalItems = totalItems;
    })
    .addCase(reimportItem.fulfilled, (state, action) => {
      state.list.map((item) => {
        if (item.id === action.payload.id) {
          item.status = 'queue';
        }

        return item;
      });
    })
    .addCase(addCCFile.fulfilled, (state, action) => {
      state.list.map((item) => {
        if (item.id === action.payload.id) {
          item.isSRTExist = true;
        }

        return item;
      });
    })
    .addCase(changeItemPageSize, (state, action) => {
      state.pageSize = action.payload;
    })
    .addCase(changeItemPage, (state, action) => {
      state.currentPage = action.payload;
    })
    .addCase(changeSelectedBuckets, (state, action) => {
      const bucketIndex = state.selectedBuckets.findIndex(id => id === action.payload);
      state.currentPage = 1;
      if ( bucketIndex !== -1) {
        state.selectedBuckets.splice(bucketIndex, 1);
      } else {
        state.selectedBuckets.push(action.payload);
      }
    })
    .addCase(changeStatusFilter, (state, action) => {
      state.statuses = action.payload ?? [];
    });
});
