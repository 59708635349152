import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API from "../../axiosAmsApi";
import { API_ROUTES } from "../../helper/apiRoutes";

export const selectPlatform = createAction('selectPlatform', (platform) => {
  return {
    payload: platform
  }
})

export const selectDate = createAction('selectDate', function prepare(date) {
  return {
    payload: {
      start: date.start.format('YYYY-MM-DD 00:00:00'),
      end: date.end.format('YYYY-MM-DD 23:59:59'),
    }
  }
});

export const downloadReport = createAsyncThunk('downloadReport',async ({ period, filters, channel }) => {
  const searchParams = new URLSearchParams({ period });

  searchParams.append('start', filters.start);
  searchParams.append('end', filters.end);
  if(channel) {
    searchParams.append('channel', channel);
  }

  if (filters.platform) {
    searchParams.append('platform', filters.platform);
  }

  const response = await API.get(API_ROUTES.report + '?' + searchParams.toString());

  return response.data;
});
