import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API from "../../axiosAmsApi";
import { API_ROUTES } from "../../helper/apiRoutes";

export const changeUserPeriod = createAction('changeUserPeriod', function prepare(period) {
  return {
    payload: period,
  };
});

export const resetUserFilters = createAction('resetUserFilters');

export const applyUserFilters = createAction('applyUserFilters', function prepare(filters) {
  return {
    payload: filters,
  }
});

export const updatePie = createAsyncThunk('updatePie',async ({ period, filters }) => {
  const searchParams = new URLSearchParams({ period });

  Object.entries(filters).forEach((filter) => {
    let field = `filters[${filter[0]}][]`;
    if (filter[1]) {
      filter[1].forEach((data) => {
        searchParams.append(field, data);
      })
    }
  });

  const response = await API.get(API_ROUTES.user + '?' + searchParams.toString());

  return response.data;
});