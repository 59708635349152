

export const getWeekDates = (offset = 0, days = 7) => {
    const today = new Date();
    const currentDay = today.getDay();

    const startWeek = new Date(today);
    startWeek.setDate(today.getDate() - currentDay + offset);

    const weekDates = [];
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    for (let i = 0; i < days; i++) {
        const date = new Date(startWeek);
        date.setDate(startWeek.getDate() + i);

        const dayOfWeek = daysOfWeek[date.getDay()];
        const day = date.getDate().toString().padStart(2, '0');
        const month = date.toLocaleString('en-US', { month: 'short' });

        weekDates.push({
            day: `${dayOfWeek} ${day} ${month}`,
            current: today.getDate() === date.getDate(),
            date: date.getFullYear() + "/" +
                ("0" + (date.getMonth()+1)).slice(-2) + "/" +
                ("0" + date.getDate()).slice(-2)
        });
    }

    return weekDates;

}

