import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../axiosApi";
import API_ROUTES from "../../apiRoutes";

export const changeSrtPageSize = createAction('changeSrtPageSize', function prepare(pageSize) {
  return { payload: pageSize };
});

export const changeSrtPage = createAction('changeSrtPage', function prepare(page) {
  return { payload: page };
});

export const getHlsChannels = createAsyncThunk('getHlsChannels', async () => {
  const response = await API.get(
    `${API_ROUTES.hls}?limit=-1&page=-1`
  );

  return response.data;
});

export const saveSrtChannel = createAsyncThunk('saveSrtChannel', async (srtChannel) => {
  const response = await API.post(`${ API_ROUTES.srt }/save`, srtChannel, {
    headers: {
      accept: 'application/json',
    },
  });

  return response.data;
});

export const getSrtChannel = createAsyncThunk('getSrtChannel', async (id) => {
  const response = await API.get(`${ API_ROUTES.srt }/${id}`);

  return response.data;
});

export const resetCurrentSrtChannel = createAction('resetCurrentSrtChannel');

export const getSrtChannels = createAsyncThunk('getSrtChannels', async (payload) => {
  const response = await API.get(
    `${ API_ROUTES.srt }?limit=${ payload.limit }&page=${ payload.page }`
  );
  return response.data;
});

export const changeCurrentSrtChannel = createAction(
  'changeCurrentSrtChannel',
  function prepare(srt) {
    return {
      payload: srt,
    };
  }
);

export const deleteSrtChannels = createAsyncThunk('deleteSrtChannels', async (ids) => {
  const response = await API.delete(
    API_ROUTES.srt,
    {
      data: { ids },
    },
    {
      headers: {
        accept: 'application/json',
      },
    }
  );
  return response.data;
});
