export const LOADING_STATUS = {
  idle: 'idle',
  loading: 'loading',
  success: 'success',
  error: 'error',
  pending: 'pending',
};

export const DATE_FORMAT = 'DD/MM/YY';

export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';

export const DEFAULT_PAGINATION = {
  limit: 50,
  page: 1,
};

export const USER_ROLE = {
  customer: 'Customer',
  admin: 'Admin',
  stormcloud: 'Stormcloud'
};

export const SORT_STATUS = {
  asc: 'asc',
  desc: 'desc',
};

export const ORDER_TYPE = {
  next: 'next',
  previous: 'previous',
};
